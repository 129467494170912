import classes from "./BodySystemBreakDown.module.css";
// import { Doughnut } from "react-chartjs-2";
// eslint-disable-next-line no-unused-vars
// import { Chart as ChartJS } from "chart.js/auto";
// import DoughnutChart from "../DoughnutChart/DoughnutChart";
import SystemCard from "../SystemCard/SystemCard";
// import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const BodySystemBreakDown = () => {
   const green = "rgba(107, 143, 89, 1)";
   const yellow = "rgba(240, 180, 41, 1)";
   const red = "rgba(217, 84, 84, 1)";

   const state = useSelector((state) => {
      return state;
   });

   const color = (value) => {
      if (value > 82.5) {
         return green;
      } else if (value > 65) {
         return yellow;
      } else return red;
   };

   return (
      // <div className={classes.container}>
      <div className={classes.cardBodySystemBreakDown}>
         <h1>Body System Breakdown</h1>
         <div className={classes.systemCardWrapperMain}>
            <div className={classes.systemCardsWrapper}>
               <SystemCard
                  color={color(state.defenceSystem.score)}
                  value={Math.round(state.defenceSystem.score).toString()}
                  system="Defence System"
                  markers={`${state.biomarkerList.defence} Biomarkers`}
                  description=" Reflects your body’s defence against infections and internal inflammation. For example, decreased levels of lymphocytes (a type of white blood cell that produces antibodies) lowers your ability to fight against bacterias or viruses."
                  slug="/defence-system"
               />
               <SystemCard
                  color={color(state.digestiveSystem.score)}
                  system="Digestive System"
                  value={Math.round(state.digestiveSystem.score).toString()}
                  markers={`${state.biomarkerList.digest} Biomarkers`}
                  description="Your body’s ability to digest food to enable normal bodily functions. For example, increased GGT (an enzyme produced by the liver) may indicate impaired bile production which is important for digestion."
                  slug="/digestive-system"
               />
            </div>
            <div className={classes.systemCardsWrapper}>
               <SystemCard
                  color={color(state.detoxSystem.score)}
                  value={Math.round(state.detoxSystem.score).toString()}
                  system="Detox System"
                  markers={`${state.biomarkerList.detox} Biomarkers`}
                  description="The ability of your body to render toxins harmless. For example, increased creatinine levels in the body may indicate poor functioning kidneys which then leads to a buildup of toxins in your body."
                  slug="/detox-system"
               />

               <SystemCard
                  color={color(state.structuralSystem.score)}
                  value={Math.round(state.structuralSystem.score).toString()}
                  system="Structural System"
                  markers={`${state.biomarkerList.structural} Biomarkers`}
                  description="Your body’s ability to have normal cellular function and strength. For example, decreased calcium levels are associated with poor cellular function."
                  slug="/structural-system"
               />
            </div>
            <div className={classes.systemCardsWrapper}>
               <SystemCard
                  color={color(state.cardiovascularSystem.score)}
                  value={Math.round(
                     state.cardiovascularSystem.score
                  ).toString()}
                  system="Cardiovascular System"
                  markers={`${state.biomarkerList.cardio} Biomarkers`}
                  description="Reflects the health status of your body’s cardiac and vascular system. For example, an elevated LDL cholesterol increases risk of cardiovascular disease."
                  slug="/cardiovascular-system"
               />

               <SystemCard
                  color={color(state.energySystem.score)}
                  value={Math.round(state.energySystem.score).toString()}
                  system="Energy System"
                  markers={`${state.biomarkerList.energy} Biomarkers`}
                  description="Reflects how well your body stores and uses energy. For example, decreased iron levels affects the efficiency of which the body uses energy."
                  slug="/energy-system"
               />
            </div>
            <div className={classes.systemCardsWrapper}>
               <SystemCard
                  color={color(state.resilienceSystem.score)}
                  value={Math.round(state.resilienceSystem.score).toString()}
                  system="Resilience System"
                  markers={`${state.biomarkerList.resilience} Biomarkers`}
                  description="Your body’s ability to adapt to challenges, maintain stamina and strength, and recover quickly and efficiently. For example, improved control of blood glucose levels is associated with increased stamina."
                  slug="/resilience-system"
               />
            </div>
         </div>
         <div className={classes.backgroundImage}></div>
      </div>
      // </div>
   );
};

export default BodySystemBreakDown;

// rgba(107, 143, 89, 1) - green
// rgba(240, 180, 41, 1) - yellow
// rgba(217, 84, 84, 1) - red
