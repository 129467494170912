import classes from "./PatientDetailsEntryPage.module.css";
import BrandLogo from "./../../assets/images/Brand logo.png";
import LogoutSvg from "../../assets/icons/log-out.svg";
import axios from "axios";
import { useEffect, useState } from "react";
import Model, { Overlay } from "../../components/Model/Model";
import Loading from "../../components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { systemsActions } from "../../store";
import TrashSvg from "./../../assets/icons/trash.svg";
import UploadSVG from "./../../assets/icons/upload.svg";

const PatientDetailsEntryPage = () => {
   const dispatch = useDispatch();
   const [biomarkers, setBiomarkers] = useState([]);
   const [modelOpen, setModelOpen] = useState(false);
   const [overlayOpen, setOverlayOpen] = useState(false);
   const [loadingOpen, setLoadingOpen] = useState(false);
   const [responseStatus, setResponseStatus] = useState("success");
   const state = useSelector((state) => {
      return state;
   });

   useEffect(async () => {
      const response = await axios.get(
         // eslint-disable-next-line no-undef
         `${process.env.REACT_APP_BACKEND_URL}/biomarkers`
      );
      setBiomarkers(response.data.biomarkers);
   }, []);

   const modelHandler = (status) => {
      if (status === false) {
         setOverlayOpen(false);
         setModelOpen(false);
      } else {
         setOverlayOpen(true);
         setModelOpen(true);
      }
   };

   const loadingHandler = (status) => {
      if (status === false) {
         setOverlayOpen(false);
         setLoadingOpen(false);
      } else {
         setOverlayOpen(true);
         setLoadingOpen(true);
      }
   };

   const textFields = () => {
      return biomarkers
         .sort(function (a, b) {
            if (a.name < b.name) {
               return -1;
            }
            if (a.name > b.name) {
               return 1;
            }
            return 0;
         })
         .map((biomarker) => {
            return (
               <div
                  key={biomarker.name}
                  className="form-group row my-3 col-md-4">
                  <label
                     htmlFor={biomarker.name}
                     className="col-form-label col-sm-3">
                     {biomarker.name}
                  </label>
                  <div className="col-sm-9">
                     <input
                        type="number"
                        className="form-control"
                        id={biomarker.name}
                        placeholder={biomarker.unit}
                     />
                  </div>
               </div>
            );
         });
   };

   const formSubmitHandler = async (event) => {
      event.preventDefault();

      const validation = formValidate();

      if (validation) {
         loadingHandler(true);
         let bioMarkers = [];

         biomarkers.forEach((biomarker) => {
            const value = document.getElementById(biomarker.name).value;
            if (value) {
               bioMarkers.push({ name: biomarker.name, value: value });
            }
         });

         const firstName = document.getElementById("firstName").value;
         const gender = document.querySelector(
            'input[name="gender"]:checked'
         ).value;
         const email = document.getElementById("email").value;
         const lastName = document.getElementById("lastName").value;
         const dob = document.getElementById("dob").value;
         const username = document.getElementById("username").value;
         const password = document.getElementById("password").value;
         const labName = document.getElementById("labName").value;
         const testedAt = document.getElementById("testDate").value;

         const isUpdateReport = state.isUpdateReport;

         let status;
         if (isUpdateReport) {
            ("executing");
            status = await axios.post(
               // eslint-disable-next-line no-undef
               `${process.env.REACT_APP_BACKEND_URL}/patientdetails/update-user`,
               {
                  firstName,
                  lastName,
                  email,
                  gender,
                  dob,
                  username,
                  password,
                  testedAt,
                  labName,
                  isUpdateReport,
                  biomarkers: bioMarkers,
               }
            );
         } else {
            status = await axios.post(
               // eslint-disable-next-line no-undef
               `${process.env.REACT_APP_BACKEND_URL}/patientdetails`,
               {
                  firstName,
                  lastName,
                  email,
                  gender,
                  dob,
                  username,
                  password,
                  testedAt,
                  labName,
                  biomarkers: bioMarkers,
               }
            );
         }
         setResponseStatus(status.status);
         loadingHandler(false);
         modelHandler(true);
      }

      if (!validation) {
         setResponseStatus("errorForm");
         modelHandler(true);
      }

      dispatch(systemsActions.setIsUpdateReport({ isUpdateReport: null }));
   };

   const formValidate = () => {
      if (
         document.getElementById("firstName").value === "" ||
         document.getElementById("lastName").value === "" ||
         document.getElementById("email").value === "" ||
         document.getElementById("username").value === "" ||
         document.getElementById("password").value === "" ||
         document.getElementById("labName").value === "" ||
         document.getElementById("testDate").value === ""
      ) {
         return false;
      }

      return true;
   };

   const clearInputFields = (preference) => {
      if (preference === "all") {
         document.getElementById("firstName").value = "";
         document.querySelector('input[name="gender"]:checked').value = "male";
         document.getElementById("email").value = "";
         document.getElementById("lastName").value = "";
         document.getElementById("dob").value = "";
         document.getElementById("username").value = "";
         document.getElementById("password").value = "";
      }
      document.getElementById("labName").value = "";
      document.getElementById("testDate").value = "";
      biomarkers.forEach((biomarker) => {
         document.getElementById(biomarker.name).value = "";
      });
   };

   const navigate = useNavigate();

   const LogoutHandler = () => {
      dispatch(systemsActions.setLoggedOutStatus());
      navigate("/");
   };

   const DeleteHandler = () => {
      navigate("/patient-delete");
   };

   const checkEmailHandler = async (event) => {
      dispatch(systemsActions.setIsUpdateReport({ isUpdateReport: null }));
      dispatch(systemsActions.setLoadingState({ status: true }));
      const email = document.getElementById("email").value;

      clearInputFields("all");

      document.getElementById("email").value = email;

      const response = await axios.post(
         // eslint-disable-next-line no-undef
         `${process.env.REACT_APP_BACKEND_URL}/patientdetails/email-check`,
         {
            email,
         }
      );

      console.log(response);
      if (response.data.status === "success") {
         document.getElementById("firstName").value =
            response.data.patient.firstName;

         if (response.data.patient.gender === "female") {
            document.getElementById("female").checked = true;
            document.getElementById("male").checked = false;
         } else {
            document.getElementById("female").checked = false;
            document.getElementById("male").checked = true;
         }

         document.getElementById("lastName").value =
            response.data.patient.lastName;
         document.getElementById("dob").valueAsDate = new Date(
            response.data.patient.dob
         );

         document.getElementById("username").value =
            response.data.patient.email;

         document.getElementById("password").value = "password";
      } else if (response.data.status === "error") {
         document.getElementById("firstName").value = "";
         document.getElementById("male").checked = true;
         document.getElementById("female").checked = false;
         document.getElementById("lastName").value = "";
         document.getElementById("dob").value = "";
         document.getElementById("username").value = "";
         document.getElementById("password").value = "";
      }
      dispatch(systemsActions.setLoadingState({ status: false }));
   };

   const checkReportHandler = async () => {
      const email = document.getElementById("email").value;
      const reportDate = document.getElementById("testDate").value;
      dispatch(systemsActions.setLoadingState({ status: true }));
      dispatch(systemsActions.setIsUpdateReport({ isUpdateReport: null }));

      clearInputFields("only");

      const response = await axios.post(
         // eslint-disable-next-line no-undef
         `${process.env.REACT_APP_BACKEND_URL}/patientdetails/get-report`,
         {
            email,
            reportDate,
         }
      );

      if (response.data.report.length > 0) {
         document.getElementById("labName").value =
            response.data.report[0].labName;

         try {
            if (response.data.report[0].reportId.biomarkers) {
               response.data.report[0].reportId.biomarkers.map((biomarker) => {
                  document.getElementById(`${biomarker.biomarker.name}`).value =
                     biomarker.biomarkerValue;
               });
            }
            // eslint-disable-next-line no-empty
         } catch (e) {}
         dispatch(
            systemsActions.setIsUpdateReport({
               isUpdateReport: {
                  reportId: response.data.report[0]._id,
                  biomarkerListId: response.data.report[0].reportId._id,
               },
            })
         );
      }
      document.getElementById("testDate").value = reportDate;

      dispatch(systemsActions.setLoadingState({ status: false }));
   };

   const UploadHandler = () => {
      navigate("/csv-upload");
   };

   return (
      <div className={classes.form}>
         <header className={classes.header}>
            <div className={classes.headerContainer}>
               <nav className={classes.nav}>
                  <div className={classes.brandContainer}>
                     <img
                        src={BrandLogo}
                        alt="Brand logo"
                        className={classes.brand}
                     />
                     <div className={classes.brandSupport}>
                        Patient Details Form
                     </div>
                  </div>
                  <div className={classes.navigationWrapper}>
                     <div
                        className={classes.uploadIconWrapper}
                        onClick={UploadHandler}>
                        <img
                           src={UploadSVG}
                           alt="Upload Icon"
                           className={classes.uploadIcon}
                        />
                     </div>
                     <div
                        className={classes.deleteIconWrapper}
                        onClick={DeleteHandler}>
                        <img
                           src={TrashSvg}
                           alt="Trash icon"
                           className={classes.trashIcon}
                        />
                     </div>
                     <div
                        className={classes.logoutIconWrapper}
                        onClick={LogoutHandler}>
                        <img
                           src={LogoutSvg}
                           alt="Home"
                           className={classes.logoutIcon}
                        />
                     </div>
                  </div>
               </nav>
            </div>
         </header>
         <div className={classes.horizontalLine}></div>
         <div className="row container">
            <div className="col-md-8">
               <div className={classes.card}>
                  <div className={classes.cardHeader}>Patient Information</div>
                  <div className="row m-2">
                     <div className="col-md-6">
                        <div className="form-group row m-3">
                           <label
                              htmlFor="email"
                              className="col-form-label col-sm-3">
                              Email
                           </label>
                           <div className="col-sm-9">
                              <input
                                 onBlur={checkEmailHandler}
                                 type="email"
                                 className="form-control"
                                 id="email"
                                 placeholder="Email"
                                 required
                              />
                           </div>
                        </div>

                        <div className="form-group row m-3">
                           <label
                              htmlFor="lastName"
                              className="col-form-label col-sm-3">
                              Last Name
                           </label>
                           <div className="col-sm-9">
                              <input
                                 type="text"
                                 className="form-control"
                                 id="lastName"
                                 placeholder="Last Name"
                                 required
                              />
                           </div>
                        </div>

                        <div className="form-group row m-3">
                           <label
                              htmlFor="gender"
                              className="col-form-label col-sm-3">
                              Gender
                           </label>
                           <div className="col-sm-9">
                              <div
                                 className="form-check"
                                 id="gender">
                                 <input
                                    className="form-check-input"
                                    type="radio"
                                    name="gender"
                                    value="male"
                                    id="male"
                                    defaultChecked={true}
                                 />
                                 <label
                                    className="form-check-label"
                                    htmlFor="male">
                                    Male
                                 </label>
                              </div>
                              <div className="form-check">
                                 <input
                                    className="form-check-input"
                                    type="radio"
                                    name="gender"
                                    value="female"
                                    id="female"
                                 />
                                 <label
                                    className="form-check-label"
                                    htmlFor="female">
                                    Female
                                 </label>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="form-group row m-3">
                           <label
                              htmlFor="firstName"
                              className="col-form-label col-sm-3">
                              First Name
                           </label>
                           <div className="col-sm-9">
                              <input
                                 type="text"
                                 className="form-control"
                                 id="firstName"
                                 placeholder="First Name"
                                 required="true"
                              />
                           </div>
                        </div>

                        <div className="form-group row m-3">
                           <label
                              htmlFor="dob"
                              className="col-form-label col-sm-3">
                              Date of birth
                           </label>
                           <div className="col-sm-9">
                              <input
                                 type="date"
                                 className="form-control"
                                 id="dob"
                                 placeholder="date of birth"
                                 required
                              />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col-md-4">
               <div className={classes.card}>
                  <div className={classes.cardHeader}>Login details</div>
                  <div className="row m-2">
                     <div className="col-md-12">
                        <div className="form-group row m-3">
                           <label
                              htmlFor="username"
                              className="col-form-label col-sm-3">
                              Username
                           </label>
                           <div className="col-sm-9">
                              <input
                                 type="text"
                                 className="form-control"
                                 id="username"
                                 placeholder="Username"
                                 required
                              />
                           </div>
                        </div>
                        <div className="form-group row m-3">
                           <label
                              htmlFor="password"
                              className="col-form-label col-sm-3">
                              Password
                           </label>
                           <div className="col-sm-9">
                              <input
                                 type="password"
                                 className="form-control"
                                 id="password"
                                 placeholder="Password"
                                 required
                              />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="row container">
            <div className="col-md-12">
               <div className={classes.card}>
                  <div className={classes.cardHeader}>Test details</div>
                  <div className="row">
                     <div className="col-md-4">
                        <div className="form-group row m-3">
                           <label
                              htmlFor="labName"
                              className="col-form-label col-sm-3">
                              Lab name
                           </label>
                           <div className="col-sm-9">
                              <input
                                 type="text"
                                 className="form-control"
                                 id="labName"
                                 placeholder="Lab name"
                                 required
                              />
                           </div>
                        </div>
                     </div>
                     <div className="col-md-4">
                        <div className="form-group row m-3">
                           <label
                              htmlFor="testDate"
                              className="col-form-label col-sm-3">
                              Test date
                           </label>
                           <div className="col-sm-9">
                              <input
                                 type="date"
                                 className="form-control"
                                 id="testDate"
                                 required
                              />
                           </div>
                        </div>
                     </div>
                     <div className="col-md-4 d-flex align-items-center">
                        <button
                           className="btn btn-warning d-inline-block"
                           onClick={checkReportHandler}>
                           Check for a report
                        </button>
                     </div>
                  </div>

                  <div className={`mt-4 ${classes.cardHeader}`}>Biomarkers</div>
                  <form>
                     <div className="mx-4">
                        <div className="row justify-content-between mx-3">
                           {textFields()}
                        </div>
                     </div>
                     <div className="row justify-content-end ">
                        <div className="col-1">
                           <button
                              className="btn btn-dark"
                              type="submit"
                              onClick={formSubmitHandler}>
                              {state.isUpdateReport ? "Update" : "Submit"}
                           </button>
                        </div>
                     </div>
                  </form>
                  <Overlay isOpen={overlayOpen} />
                  <Loading isOpen={loadingOpen} />
                  <Model
                     isOpen={modelOpen}
                     modelHandler={modelHandler}
                     responseStatus={responseStatus}
                     clearInputFields={clearInputFields}
                  />
               </div>
            </div>
         </div>
      </div>
   );
};

export default PatientDetailsEntryPage;
