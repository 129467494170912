// @ts-nocheck
import classes from "./Login.module.css";
import BrandLogo from "./../../assets/images/Brand logo.png";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { systemsActions } from "./../../store/index";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
const Login = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const username = useRef();
   const password = useRef();
   const [errorText, setErrorText] = useState(
      `${classes.errorText}  ${classes.displayNone}`
   );
   const recaptchaRef = useRef(null);

   useEffect(() => {
      dispatch(
         systemsActions.setReportLastDate({
            reportLastDate: null,
         })
      );

      dispatch(
         systemsActions.setSecondReportDate({
            secondReportDate: null,
         })
      );

      dispatch(
         systemsActions.setThirdReportDate({
            thirdReportDate: null,
         })
      );

      dispatch(
         systemsActions.setFourthReportDate({
            fourthReportDate: null,
         })
      );

      dispatch(
         systemsActions.setFifthReportDate({
            fifthReportDate: null,
         })
      );
      dispatch(systemsActions.setLoggedOutStatus());
   }, []);

   const showPasswordHandler = () => {
      setShowPassword(!showPassword);
   };

   const loginSubmitHandler = async (event) => {
      event.preventDefault();
      dispatch(systemsActions.setLoadingState({ status: true }));
      const loginUsername = username.current.value;
      const loginPassword = password.current.value;

      const captchaToken = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();

      try {
         let response = await axios.post(
            // eslint-disable-next-line no-undef
            `${process.env.REACT_APP_BACKEND_URL}/auth`,
            {
               username: loginUsername,
               password: loginPassword,
               captchaToken: captchaToken,
               secretKey: `${process.env.REACT_APP_SECRET_KEY}`,
            }
         );
         dispatch(systemsActions.setLoadingState({ status: false }));
         if (response.data.status === "success") {
            dispatch(systemsActions.setLoggedInStatus());
            if (response.data.patient.isAdmin) {
               dispatch(
                  systemsActions.setPatientState({
                     patient: response.data.patient,
                  })
               );
               navigate("/patient-details");
            } else {
               dispatch(
                  systemsActions.setData({
                     user: { username: loginUsername, password: loginPassword },
                  })
               );
               dispatch(
                  systemsActions.setPatientState({
                     patient: response.data,
                  })
               );
               setErrorText(`${classes.errorText}  ${classes.displayNone}`);
               navigate("/dashboard");
            }
         }
      } catch (e) {
         dispatch(systemsActions.setLoadingState({ status: false }));
         setErrorText(`${classes.errorText}`);
      }
   };

   const [showPassword, setShowPassword] = useState(false);
   return (
      <>
         <div className={classes.backgroundWrapper}>
            <div className={classes.container}>
               <div className={classes.brandImageWrapper}>
                  <img
                     src={BrandLogo}
                     alt="brand"
                     className={classes.brandImage}
                  />
               </div>
               <div className={classes.loginHeader}>CHI-TREE LOGIN</div>
               <div>
                  <form className={classes.loginFormWrapper}>
                     <input
                        type="text"
                        placeholder="Username"
                        className={classes.inputField}
                        ref={username}
                     />
                     <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        className={classes.inputField}
                        ref={password}
                     />
                     <div>
                        <label className={classes.showPassword}>
                           <input
                              type="checkbox"
                              className={classes.checkBox}
                              defaultChecked={showPassword}
                              onChange={showPasswordHandler}
                           />
                           Show Password
                        </label>
                     </div>
                     <p className={errorText}>
                        **Please check the credentials and try again
                     </p>

                     <button
                        type="submit"
                        onClick={loginSubmitHandler}
                        className={classes.submitButton}>
                        LOGIN
                     </button>
                  </form>
               </div>
            </div>
            <ReCAPTCHA
               sitekey={process.env.REACT_APP_SITE_KEY}
               size="invisible"
               // style={{ borderRadius: "10px", overflow: "hidden" }}
               badge="bottomright"
               theme="light"
               ref={recaptchaRef}
            />
         </div>
      </>
   );
};

export default Login;
