import classes from "./PatientDeletePage.module.css";
import BrandLogo from "./../../assets/images/Brand logo.png";
import LogoutSvg from "../../assets/icons/log-out.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { systemsActions } from "../../store";
import AdminSvg from "./../../assets/icons/admin.svg";
import { useState } from "react";
import Model, { Overlay } from "../../components/Model/Model";
import Loading from "../../components/Loading/Loading";
import axios from "axios";
import UploadSVG from "./../../assets/icons/upload.svg";

const PatientDeletePage = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const [value, setValue] = useState("delete-user");

   const LogoutHandler = () => {
      dispatch(systemsActions.setLoggedOutStatus());
      navigate("/");
   };

   const [modelOpen, setModelOpen] = useState(false);
   const [overlayOpen, setOverlayOpen] = useState(false);
   const [loadingOpen, setLoadingOpen] = useState(false);
   const [responseStatus, setResponseStatus] = useState("confirmation");
   const [name, setName] = useState("");
   const [dob, setDob] = useState("");
   const [gender, setGender] = useState("");
   const [reportCount, setReportCount] = useState("");
   const [email, setEmail] = useState("");
   const [reportDate, setReportDate] = useState("");
   const [labName, setLabName] = useState("");

   const AdminHandler = () => {
      navigate("/patient-details");
   };

   const handleChange = (event) => {
      setValue(event.target.value);
   };

   const UploadHandler = () => {
      navigate("/csv-upload");
   };

   const modelHandler = (status, submitStatus) => {
      if (status === false) {
         setOverlayOpen(false);
         setModelOpen(false);
      } else {
         setOverlayOpen(true);
         setModelOpen(true);
      }

      if (submitStatus) {
         if (value === "delete-user") {
            deleteUser();
         }
         if (value === "delete-report") {
            deleteReport();
            // console.log("Delete Report");
         }
      }
   };

   const deleteReport = async () => {
      dispatch(systemsActions.setLoadingState({ status: true }));

      const response = await axios.post(
         // eslint-disable-next-line no-undef
         `${process.env.REACT_APP_BACKEND_URL}/patientdetails/delete-report`,
         {
            email,
            reportDate: document.getElementById("reportDate").value,
         }
      );

      if (response.data.status === "success") {
         setResponseStatus("api-success");
         document.getElementById("email").value = "";
         document.getElementById("reportDate").value = "";
      } else {
         setResponseStatus("api-error");
      }

      dispatch(systemsActions.setLoadingState({ status: false }));
      modelHandler();
   };

   const checkReportHandler = async () => {
      dispatch(systemsActions.setLoadingState({ status: true }));
      setReportDate("");
      setLabName("");

      try {
         const response = await axios.post(
            // eslint-disable-next-line no-undef
            `${process.env.REACT_APP_BACKEND_URL}/patientdetails/get-report`,
            {
               email,
               reportDate: document.getElementById("reportDate").value,
            }
         );

         if (response.data.report.length > 0) {
            const date =
               new Date(response.data.report[0].testedAt).getFullYear() +
               "/" +
               (new Date(response.data.report[0].testedAt).getMonth() + 1) +
               "/" +
               new Date(response.data.report[0].testedAt).getDate();

            setReportDate(date);
            setLabName(response.data.report[0].labName);
         } else {
            setReportDate("");
            setLabName("");
         }
      } catch (e) {
         setReportDate("");
         setLabName("");
      }

      dispatch(systemsActions.setLoadingState({ status: false }));
   };

   const deleteUser = async () => {
      dispatch(systemsActions.setLoadingState({ status: true }));

      const response = await axios.post(
         // eslint-disable-next-line no-undef
         `${process.env.REACT_APP_BACKEND_URL}/patientdetails/delete-user`,
         {
            email,
         }
      );

      if (response.data.status === "success") {
         setResponseStatus("api-success");
         document.getElementById("email").value = "";
      } else {
         setResponseStatus("api-error");
      }

      dispatch(systemsActions.setLoadingState({ status: false }));
      modelHandler();
   };

   const onClickHandler = () => {
      if (email && name) {
         setResponseStatus("confirmation");
         modelHandler(true);
      } else {
         setResponseStatus("api-error");
         modelHandler(true);
      }
   };

   const clearInputFields = (value) => {
      setName("");
      setGender("");
      setDob("");
      setReportCount("");
      setLabName("");
      setReportDate("");
      setResponseStatus("confirmation");
   };

   const checkEmailHandler = async (event) => {
      dispatch(systemsActions.setLoadingState({ status: true }));
      const email = document.getElementById("email").value;
      setEmail(document.getElementById("email").value);

      document.getElementById("email").value = email;

      const response = await axios.post(
         // eslint-disable-next-line no-undef
         `${process.env.REACT_APP_BACKEND_URL}/patientdetails/email-check`,
         {
            email,
         }
      );

      if (response.data.status === "success") {
         setName(
            response.data.patient.firstName +
               " " +
               response.data.patient.lastName
         );
         setGender(response.data.patient.gender);
         setDob(response.data.patient.dob);
         setReportCount(response.data.patient.reports.length);
      } else if (response.data.status === "error") {
         clearInputFields();
      }
      dispatch(systemsActions.setLoadingState({ status: false }));
   };

   return (
      <>
         <header className={classes.header}>
            <div className={classes.headerContainer}>
               <nav className={classes.nav}>
                  <div className={classes.brandContainer}>
                     <img
                        src={BrandLogo}
                        alt="Brand logo"
                        className={classes.brand}
                     />
                     <div className={classes.brandSupport}>
                        Patient Delete Form
                     </div>
                  </div>
                  <div className={classes.navigationWrapper}>
                     <div
                        className={classes.uploadIconWrapper}
                        onClick={UploadHandler}>
                        <img
                           src={UploadSVG}
                           alt="Upload Icon"
                           className={classes.uploadIcon}
                        />
                     </div>
                     <div
                        className={classes.adminIconWrapper}
                        onClick={AdminHandler}>
                        <img
                           src={AdminSvg}
                           alt="Home"
                           className={classes.adminIcon}
                        />
                     </div>
                     <div
                        className={classes.logoutIconWrapper}
                        onClick={LogoutHandler}>
                        <img
                           src={LogoutSvg}
                           alt="Home"
                           className={classes.logoutIcon}
                        />
                     </div>
                  </div>
               </nav>
            </div>
         </header>
         <div className={classes.horizontalLine}></div>
         <section className={classes.deleteWrapper}>
            <div className={classes.deleteContainer}>
               <div className={classes.headerSelection}>
                  <div className={classes.selection}>
                     <h2 className={classes.headerSelectionText}>
                        Select the option :
                     </h2>
                     <div>
                        <label>
                           <div className={classes.dropdownWrapper}>
                              <select
                                 className={classes.dropdownMenu}
                                 placeholder="Select System"
                                 value={value}
                                 onChange={handleChange}>
                                 <option
                                    key={1}
                                    selected={value === "delete-user"}
                                    value={"delete-user"}>
                                    {"Delete User"}
                                 </option>
                                 <option
                                    key={2}
                                    selected={value === "delete-report"}
                                    value={"delete-report"}>
                                    {"Delete Report"}
                                 </option>
                              </select>
                           </div>
                        </label>
                     </div>
                  </div>
                  <div className={classes.textFields}>
                     <div>
                        <label>Email</label>
                        <input
                           onBlur={checkEmailHandler}
                           alt="email"
                           type="email"
                           id="email"
                           placeholder="Email"
                        />
                     </div>
                     {value !== "delete-user" && (
                        <div>
                           <label>Report Date</label>

                           <input
                              alt="date"
                              type="date"
                              id="reportDate"
                              placeholder="Date"
                           />
                        </div>
                     )}
                     <button
                        className={classes.searchButton}
                        onClick={checkReportHandler}>
                        SEARCH
                     </button>
                  </div>
               </div>
               <div className={classes.detailsArea}>
                  {value === "delete-user" && (
                     <div className={classes.userDetails}>
                        <h3>User Details</h3>
                        <div className={classes.userDetailsWrapper}>
                           <div className={classes.userDetailsTextGroup}>
                              <div className={classes.userDetailsText}>
                                 Name:{" "}
                              </div>
                              <div className={classes.userDetailsText}>
                                 {name}
                              </div>
                           </div>
                           <div className={classes.userDetailsTextGroup}>
                              <div className={classes.userDetailsText}>
                                 DOB:
                              </div>
                              <div className={classes.userDetailsText}>
                                 {new Date(dob).getDate() +
                                    "/" +
                                    (new Date(dob).getMonth() + 1) +
                                    "/" +
                                    new Date(dob).getFullYear()}
                              </div>
                           </div>
                           <div className={classes.userDetailsTextGroup}>
                              <div className={classes.userDetailsText}>
                                 Gender:
                              </div>
                              <div className={classes.userDetailsText}>
                                 {gender}
                              </div>
                           </div>
                        </div>
                        <div className={classes.userDetailsWrapper}>
                           <div className={classes.userDetailsTextGroup}>
                              <div className={classes.userDetailsText}>
                                 Report Count:
                              </div>
                              <div className={classes.userDetailsText}>
                                 {reportCount}
                              </div>
                           </div>
                        </div>
                     </div>
                  )}

                  {value === "delete-report" && (
                     <div className={classes.userDetails}>
                        <h3>Report Details</h3>
                        <div className={classes.userDetailsWrapper}>
                           <div className={classes.userDetailsTextGroup}>
                              <div className={classes.userDetailsText}>
                                 Name:
                              </div>
                              <div className={classes.userDetailsText}>
                                 {name}
                              </div>
                           </div>
                           <div className={classes.userDetailsTextGroup}>
                              <div className={classes.userDetailsText}>
                                 Report Date:
                              </div>
                              <div className={classes.userDetailsText}>
                                 {reportDate}
                              </div>
                           </div>
                           <div className={classes.userDetailsTextGroup}>
                              <div className={classes.userDetailsText}>
                                 Lab name:
                              </div>
                              <div className={classes.userDetailsText}>
                                 {labName}
                              </div>
                           </div>
                        </div>
                     </div>
                  )}
               </div>
               <div className={classes.buttonWrapper}>
                  <button
                     className={classes.deleteButton}
                     onClick={onClickHandler}>
                     DELETE
                  </button>
               </div>
            </div>
         </section>

         <Overlay isOpen={overlayOpen} />
         <Loading isOpen={loadingOpen} />
         <Model
            isOpen={modelOpen}
            modelHandler={modelHandler}
            responseStatus={responseStatus}
            clearInputFields={clearInputFields}
         />
      </>
   );
};

export default PatientDeletePage;
