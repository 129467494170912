// eslint-disable-next-line no-unused-vars
import { Chart as Chartjs } from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import classes from "./BarChart.module.css";
import { isLabelWithInternallyDisabledControl } from "@testing-library/user-event/dist/utils";

Chart.register(ChartDataLabels);

const BarChart = (props) => {
   const { labelList, colors, title, subTitle, dataValues } = props;
   const labels = labelList;
   const data = {
      labels: labels,
      datasets: [
         {
            label: "My First Dataset",
            data: dataValues,
            backgroundColor: colors,
            borderWidth: 0,
         },
      ],
   };

   return (
      <div className={classes.barChart}>
         <p>
            <b>{title}</b> {subTitle}
         </p>
         <Bar
            data={data}
            height={150}
            className={classes.barchart}
            width={150}
            options={{
               maintainAspectRatio: true,
               responsive: true,
               scales: {
                  y: {
                     beginAtZero: true,
                     display: false,
                  },
                  x: {
                     ticks: {
                        font: {
                           size: 15,
                           family: '"Nunito Sans", "sans-serif"',

                           // weight: "bold",
                        },
                     },
                  },
               },
               plugins: {
                  tooltip: {
                     enabled: false,
                  },
                  legend: {
                     display: false,
                     labels: {
                        // This more specific font property overrides the global property
                     },
                  },
                  datalabels: {
                     anchor: "start",
                     offset: 8,
                     rotation: 270,
                     color: "rgba(0,0,0, 0.6)",
                     padding: 0,
                     align: "end",
                     formatter: function (value, index, values) {
                        if (value >= 0) {
                           return value;
                        } else {
                           value = "";
                           return value;
                        }
                     },
                     font: {
                        size: 13,
                        family: '"Nunito Sans", "sans-serif"',
                        weight: 700,
                     },
                  },

                  // cutout: 32,
               },
            }}
         />
      </div>
   );
};

export default BarChart;
