import classes from "./CSVUpload.module.css";
import BrandLogo from "./../../assets/images/Brand logo.png";
import info from "./../../assets/icons/info.svg";
import Files from "react-files";
import { useState } from "react";
import Axios from "axios";
import Model, { Overlay } from "../../components/Model/Model";
import Loading from "../../components/Loading/Loading";
import { useDispatch } from "react-redux";
import { systemsActions } from "../../store";
import AdminSvg from "./../../assets/icons/admin.svg";
import { useNavigate } from "react-router-dom";

const CSVUpload = () => {
   const [file, setFile] = useState(null);
   const [modelOpen, setModelOpen] = useState(false);
   const [overlayOpen, setOverlayOpen] = useState(false);
   const [loadingOpen, setLoadingOpen] = useState(false);
   const [responseStatus, setResponseStatus] = useState("confirmation");
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const infoClickHandler = () => {
      setResponseStatus("info");
      modelHandler(true);
   };

   const modelHandler = (status, submitStatus = false) => {
      if (status === false) {
         console.log("closing model");
         setOverlayOpen(false);
         setModelOpen(false);
      } else {
         setOverlayOpen(true);
         setModelOpen(true);
      }

      if (submitStatus) {
         uploadHandler();
      }
   };

   const onFileUpload = (files) => {
      setResponseStatus("confirmation");
      setFile(files[0]);
   };

   const clearInputFields = () => {
      setFile(null);
   };

   const onUploadHandler = () => {
      modelHandler(true);
   };

   const uploadHandler = async () => {
      dispatch(systemsActions.setLoadingState({ status: true }));

      let formData = new FormData();

      formData.append("csvFile", file);
      // eslint-disable-next-line no-undef
      Axios.post(`${process.env.REACT_APP_BACKEND_URL}/csv-upload`, formData, {
         headers: {
            "Content-Type": "multipart/form-data",
         },
      })
         .then((res) => {
            if (res.data.status === "success") {
               setResponseStatus("api-success");
               dispatch(systemsActions.setLoadingState({ status: false }));
               modelHandler();
            } else {
               setResponseStatus("api-error");
               dispatch(systemsActions.setLoadingState({ status: false }));
               modelHandler();
            }
         })
         .catch((e) => {
            setResponseStatus("api-error");
            dispatch(systemsActions.setLoadingState({ status: false }));
            modelHandler();
         });
   };

   const AdminHandler = () => {
      navigate("/patient-details");
   };

   return (
      <div>
         <header className={classes.header}>
            <div className={classes.headerContainer}>
               <nav className={classes.nav}>
                  <div className={classes.brandContainer}>
                     <img
                        src={BrandLogo}
                        alt="Brand logo"
                        className={classes.brand}
                     />
                     <div className={classes.brandSupport}>
                        CHI -TREE CSV UPLOAD
                     </div>
                  </div>
                  <button
                     className={classes.infoButton}
                     onClick={infoClickHandler}>
                     <div className={classes.wrapper}>
                        <div
                           className={classes.adminIconWrapper}
                           onClick={AdminHandler}>
                           <img
                              src={AdminSvg}
                              alt="Home"
                              className={classes.adminIcon}
                           />
                        </div>
                        <img
                           src={info}
                           alt="info logo"
                           className={classes.info}
                        />
                     </div>
                  </button>
               </nav>
            </div>
         </header>
         <div className={classes.horizontalLine}></div>
         <section className={classes.uploadSection}>
            <Files
               className={classes.dropZone}
               onChange={onFileUpload}>
               Drop Files here or click to upload
            </Files>
            {file && <p className={classes.filename}>File Name: {file.name}</p>}
            {!file && <p className={classes.filename}>No file selected</p>}
            {file && (
               <button
                  className={classes.buttonUpload}
                  onClick={onUploadHandler}>
                  UPLOAD
               </button>
            )}
            {!file && (
               <button
                  disabled
                  className={classes.buttonDisabled}>
                  UPLOAD
               </button>
            )}
         </section>
         <Overlay isOpen={overlayOpen} />
         <Model
            isOpen={modelOpen}
            modelHandler={modelHandler}
            responseStatus={responseStatus}
            clearInputFields={clearInputFields}
         />
         <Loading isOpen={loadingOpen} />
      </div>
   );
};

export default CSVUpload;
