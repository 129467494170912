export const systemListServices = (systemList) => {
   let detoxSystemBiomarkerList;
   let energySystemBiomarkerList;
   let defenceSystemBiomarkerList;
   let digestiveSystemBiomarkerList;
   let resilienceSystemBiomarkerList;
   let structuralSystemBiomarkerList;
   let cardiovascularSystemBiomarkerList;
   systemList.map((system) => {
      if (system.systemName === "defenceSystem") {
         defenceSystemBiomarkerList = system.systemBiomarkerList;
      } else if (system.systemName === "digestiveSystem") {
         digestiveSystemBiomarkerList = system.systemBiomarkerList;
      } else if (system.systemName === "detoxSystem") {
         detoxSystemBiomarkerList = system.systemBiomarkerList;
      } else if (system.systemName === "resilienceSystem") {
         resilienceSystemBiomarkerList = system.systemBiomarkerList;
      } else if (system.systemName === "structuralSystem") {
         structuralSystemBiomarkerList = system.systemBiomarkerList;
      } else if (system.systemName === "cardiovascularSystem") {
         cardiovascularSystemBiomarkerList = system.systemBiomarkerList;
      } else {
         energySystemBiomarkerList = system.systemBiomarkerList;
      }
   });

   return [
      detoxSystemBiomarkerList,
      energySystemBiomarkerList,
      defenceSystemBiomarkerList,
      digestiveSystemBiomarkerList,
      resilienceSystemBiomarkerList,
      structuralSystemBiomarkerList,
      cardiovascularSystemBiomarkerList,
   ];
};
