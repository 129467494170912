import { createPortal } from "react-dom";
import ReactLoading from "react-loading";
import classes from "./Loading.module.css";

const Loading = ({ isOpen }) => {
   if (!isOpen) return null;

   return createPortal(
      <div className={classes.LoadingWrapper}>
         <ReactLoading
            type="cylon"
            color="black"
         />
      </div>,
      document.getElementById("model")
   );
};

export default Loading;
