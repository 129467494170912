exports.calculateScore = (value, optimal) => {
   let diviation = Math.floor((value / optimal) * 50);

   let points;
   if (diviation > 100) {
      points = 0;
   } else if (diviation > 50) {
      points = 100 - (diviation - 50) * 2;
   } else {
      points = diviation * 2;
   }

   return points;
};
