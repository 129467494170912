// @ts-nocheck
import axios from "axios";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HomeSvg from "../../assets/icons/home.svg";
import LogoutSvg from "../../assets/icons/log-out.svg";
import PrintSvg from "../../assets/icons/printer.svg";
import BodySystemBreakDown from "../../components/BodySystemBreakDown/BodySystemBreakDown";
import OverallHealthScore from "../../components/OverallHealthScore/OverallHealthScore";
import BrandLogo from "./../../assets/images/Brand logo.png";
import { DropDown } from "./../../components/DropDown/DropDown";
import Footer from "./../../components/Footer/Footer";
import { systemsActions } from "./../../store/index";
import classes from "./Home.module.css";
import { sortLimit } from "./Services/sort-limit.service";
import { systemListServices } from "./Services/system-list.service";
import { calculateScore } from "./Services/score-calculate";
import SystemTemplate from "../SystemTemplate/SystemTemplate";

// import { pageStyle } from "../common";

// size: A3 landscape !important;

const Home = () => {
   const [printPageBool, setPrintPageBool] = useState(false);
   const [showHome, setShowHome] = useState(true);
   let componentRef = useRef(null);
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const loggedIn = useSelector((state) => {
      return state.isLoggedIn;
   });
   const userCredentials = useSelector((state) => {
      return state.user;
   });

   const state = useSelector((state) => {
      return state;
   });
   const patient = useSelector((state) => {
      return state.patient;
   });
   const systems = useSelector((state) => {
      return state.systems;
   });

   const defence = systems.defence;
   const cardiovascular = systems.cardiovascular;
   const detox = systems.detox;
   const digestive = systems.digestive;
   const energy = systems.energy;
   const resilience = systems.resilience;
   const structural = systems.structural;

   const supporterFunction = (score, count) => {
      if (score === 0) {
         return 0;
      } else {
         return Math.round(score / count);
      }
   };

   const checkSystemFunction = (count) => {
      if (count) {
         return 1;
      }
      return 0;
   };

   let finalReportDate;
   let finalReportLabName;
   let finalReportScore;

   let previousLatestReportDate;
   let previousLatestReportLabName;
   let previousLatestReportScore;

   let previousOldReportDate;
   let previousOldReportLabName;
   let previousOldReportScore;

   let showOldReport;
   let showRecentReport;

   useEffect(async () => {
      console.log("Logged In: " + loggedIn);
      if (!loggedIn) {
         navigate("/");
      }

      // setCurrentState(state);

      let response = {};
      response.data = patient;
      //  = await axios.post(
      //    // eslint-disable-next-line no-undef
      //    `${process.env.REACT_APP_BACKEND_URL}/auth`,
      //    // "http}://localhost:3000/auth",
      //    {
      //       username: userCredentials.username,
      //       password: userCredentials.password,
      //    }
      // );

      dispatch(systemsActions.setLoadingState({ status: true }));
      const systemResponse = await axios.get(
         // eslint-disable-next-line no-undef
         `${process.env.REACT_APP_BACKEND_URL}/biomarkers/system`
         // "http://localhost:3000/biomarkers/system"
      );

      let detoxSystemBiomarkers = [];
      let energySystemBiomarkers = [];
      let defenceSystemBiomarkers = [];
      let digestiveSystemBiomarkers = [];
      let structuralSystemBiomarkers = [];
      let resilienceSystemBiomarkers = [];
      let cardiovascularSystemBiomarkers = [];

      // let detoxSystemBiomarkerList;
      // let energySystemBiomarkerList;
      // let defenceSystemBiomarkerList;
      // let digestiveSystemBiomarkerList;
      // let resilienceSystemBiomarkerList;
      // let structuralSystemBiomarkerList;
      // let cardiovascularSystemBiomarkerList;

      let [
         detoxSystemBiomarkerList,
         energySystemBiomarkerList,
         defenceSystemBiomarkerList,
         digestiveSystemBiomarkerList,
         resilienceSystemBiomarkerList,
         structuralSystemBiomarkerList,
         cardiovascularSystemBiomarkerList,
      ] = systemListServices(systemResponse.data.systems[0].system);

      // systemResponse.data.systems[0].system.map((system) => {
      //    if (system.systemName === "defenceSystem") {
      //       defenceSystemBiomarkerList = system.systemBiomarkerList;
      //    } else if (system.systemName === "digestiveSystem") {
      //       digestiveSystemBiomarkerList = system.systemBiomarkerList;
      //    } else if (system.systemName === "detoxSystem") {
      //       detoxSystemBiomarkerList = system.systemBiomarkerList;
      //    } else if (system.systemName === "resilienceSystem") {
      //       resilienceSystemBiomarkerList = system.systemBiomarkerList;
      //    } else if (system.systemName === "structuralSystem") {
      //       structuralSystemBiomarkerList = system.systemBiomarkerList;
      //    } else if (system.systemName === "cardiovascularSystem") {
      //       cardiovascularSystemBiomarkerList = system.systemBiomarkerList;
      //    } else {
      //       energySystemBiomarkerList = system.systemBiomarkerList;
      //    }
      // });

      // Store System Biomarker List
      const systems = {
         defence: defenceSystemBiomarkerList,
         digestive: digestiveSystemBiomarkerList,
         detox: detoxSystemBiomarkerList,
         resilience: resilienceSystemBiomarkerList,
         structural: structuralSystemBiomarkerList,
         cardiovascular: cardiovascularSystemBiomarkerList,
         energy: energySystemBiomarkerList,
      };
      dispatch(systemsActions.setSystems({ systems }));

      // Sort and filter the last five recent records

      let { reports } = response.data.patient;
      let sortedReports = sortLimit(reports, 5);

      // reports = [...reports, { name: "mynamishihan" }];

      // response.data.patient.reports.sort(function (a, b) {
      //    return new Date(b.testedAt) - new Date(a.testedAt);
      // });
      // response.data.patient.reports = response.data.patient.reports.slice(0, 5);

      // Calculate the System Specific Score
      let digestiveSystemScore = 0;
      let digestiveSystemCount = 0;
      let defenceSystemCount = 0;
      let cardiovascularSystemCount = 0;
      let detoxSystemCount = 0;
      let energySystemCount = 0;
      let resilienceSystemCount = 0;
      let structuralSystemCount = 0;
      let defenceSystemScore = 0;
      let cardiovascularSystemScore = 0;
      let detoxSystemScore = 0;
      let energySystemScore = 0;
      let resilienceSystemScore = 0;
      let structuralSystemScore = 0;
      let warningBiomarkerCount = 0;
      let dangerBiomarkerCount = 0;

      let latestReport;
      let previousOldReport;
      let previousLatestReport;

      if (sortedReports[0]) {
         latestReport = sortedReports[0].reportId;
         finalReportLabName = sortedReports[0].labName;
         finalReportDate = sortedReports[0].testedAt;
         dispatch(
            systemsActions.setReportLastDate({
               reportLastDate: finalReportDate,
            })
         );
      }
      if (sortedReports[1]) {
         previousLatestReport = sortedReports[1].reportId;
         previousLatestReportDate = sortedReports[1].testedAt;
         previousLatestReportLabName = sortedReports[1].labName;
         dispatch(
            systemsActions.setSecondReportDate({
               secondReportDate: previousLatestReportDate,
            })
         );
      } else {
         previousLatestReport = {};
         previousLatestReportDate = "--  --  ----";
         previousLatestReportLabName = "----";
      }

      if (sortedReports[2]) {
         previousOldReport = sortedReports[2].reportId;
         previousOldReportDate = sortedReports[2].testedAt;
         previousOldReportLabName = sortedReports[2].labName;
         dispatch(
            systemsActions.setThirdReportDate({
               thirdReportDate: previousOldReportDate,
            })
         );
      } else {
         previousOldReport = {};
         previousOldReportDate = "-- -- ----";
         previousOldReportLabName = "----";
      }

      if (sortedReports[3]) {
         dispatch(
            systemsActions.setFourthReportDate({
               fourthReportDate: sortedReports[3].testedAt,
            })
         );
      }

      if (sortedReports[4]) {
         dispatch(
            systemsActions.setFifthReportDate({
               fifthReportDate: sortedReports[4].testedAt,
            })
         );
      }

      if (latestReport) {
         try {
            latestReport.biomarkers.map((biomarker) => {
               // let value = biomarker.biomarkerValue;
               // let high = biomarker.biomarker.rangeHigh;
               // let low = biomarker.biomarker.rangeLow;
               // let score = biomarker.score;

               let value = biomarker.biomarkerValue;
               let high;
               let low;
               let score;

               if (biomarker.biomarker.filter) {
                  if (
                     patient.patient.gender === "male" ||
                     patient.patient.gender === "Male"
                  ) {
                     high = biomarker.biomarker.male.rangeHigh;
                     low = biomarker.biomarker.male.rangeLow;
                  } else {
                     high = biomarker.biomarker.female.rangeHigh;
                     low = biomarker.biomarker.female.rangeLow;
                  }
               } else {
                  high = biomarker.biomarker.rangeHigh;
                  low = biomarker.biomarker.rangeLow;
               }

               score = calculateScore(value, (high + low) / 2);

               if (
                  defenceSystemBiomarkerList.includes(
                     biomarker.biomarker.name
                  ) ||
                  digestiveSystemBiomarkerList.includes(
                     biomarker.biomarker.name
                  ) ||
                  detoxSystemBiomarkerList.includes(biomarker.biomarker.name) ||
                  resilienceSystemBiomarkerList.includes(
                     biomarker.biomarker.name
                  ) ||
                  structuralSystemBiomarkerList.includes(
                     biomarker.biomarker.name
                  ) ||
                  cardiovascularSystemBiomarkerList.includes(
                     biomarker.biomarker.name
                  ) ||
                  energySystemBiomarkerList.includes(biomarker.biomarker.name)
               ) {
                  if (value > high || value < low) {
                     dangerBiomarkerCount++;
                  } else if (value > low && value < high && score < 82.5) {
                     warningBiomarkerCount++;
                  }
               }
               // console.log(biomarker.biomarkerValue);
               // console.log(biomarker.score);
               // if(biomarker.biomarker.rangeLow)

               // if (biomarker.score > 65 && biomarker.score < 82.5) {
               // TODO: figure out the score accordingly
               // if (
               //    biomarker.biomarkerValue > biomarker.biomarker.rangeHigh ||
               //    biomarker.biomarkerValue < biomarker.biomarker.rangeLow
               // ) {
               //    dangerBiomarkerCount++;
               // } else {
               // warningBiomarkerCount++;
               // }
               // } else
               // if (biomarker.score < 65) {
               // if (
               //    biomarker.biomarkerValue < biomarker.biomarker.rangeHigh ||
               //    biomarker.biomarkerValue > biomarker.biomarker.rangeLow
               // ) {
               //    warningBiomarkerCount++;
               // } else {
               // dangerBiomarkerCount++;
               // }
               // }
               const bioMarker = biomarker;
               if (bioMarker.biomarker) {
                  let high;
                  let low;
                  let value;

                  if (bioMarker.biomarker.filter) {
                     if (
                        patient.patient.gender === "male" ||
                        patient.patient.gender === "Male"
                     ) {
                        high = bioMarker.biomarker.male.rangeHigh;
                        low = bioMarker.biomarker.male.rangeLow;
                     } else {
                        high = bioMarker.biomarker.female.rangeHigh;
                        low = bioMarker.biomarker.female.rangeLow;
                     }
                  } else {
                     high = bioMarker.biomarker.rangeHigh;
                     low = bioMarker.biomarker.rangeLow;
                  }

                  value = bioMarker.biomarkerValue;

                  digestiveSystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        //TODO: calculating the score dynamically
                        let score = calculateScore(value, (high + low) / 2);
                        digestiveSystemScore += score;
                        digestiveSystemCount++;
                     }
                  });
                  defenceSystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        defenceSystemScore += score;
                        defenceSystemCount++;
                     }
                  });
                  cardiovascularSystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        cardiovascularSystemScore += score;
                        cardiovascularSystemCount++;
                     }
                  });
                  detoxSystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        detoxSystemScore += score;
                        detoxSystemCount++;
                     }
                  });
                  energySystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        energySystemScore += score;
                        energySystemCount++;
                     }
                  });
                  resilienceSystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        resilienceSystemScore += score;
                        resilienceSystemCount++;
                     }
                  });
                  structuralSystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        structuralSystemScore += score;
                        structuralSystemCount++;
                     }
                  });
               }
            });
            // eslint-disable-next-line no-empty
         } catch (e) {}
      }

      dispatch(
         systemsActions.setHomePageMarkerCount({
            homePageMarkerCount: {
               warning: warningBiomarkerCount,
               danger: dangerBiomarkerCount,
            },
         })
      );

      if (response.data) {
         sortedReports.map((report) => {
            let currentReportBiomarkers = report.reportId.biomarkers.slice();
            currentReportBiomarkers.map((biomarker) => {
               let bioMarker = biomarker;

               let high;
               let low;
               let value;

               if (bioMarker.biomarker.filter) {
                  if (
                     patient.patient.gender === "male" ||
                     patient.patient.gender === "Male"
                  ) {
                     high = bioMarker.biomarker.male.rangeHigh;
                     low = bioMarker.biomarker.male.rangeLow;
                  } else {
                     high = bioMarker.biomarker.female.rangeHigh;
                     low = bioMarker.biomarker.female.rangeLow;
                  }
               } else {
                  high = bioMarker.biomarker.rangeHigh;
                  low = bioMarker.biomarker.rangeLow;
               }

               value = bioMarker.biomarkerValue;

               if (bioMarker.biomarker) {
                  digestiveSystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);

                        // bioMarker.score = score;
                        const newBiomarker = {
                           ...bioMarker,
                           score,
                           createdAt: report.testedAt,
                        };

                        // bioMarker.createdAt = report.testedAt;
                        digestiveSystemBiomarkers.push(newBiomarker);
                     }
                  });
                  defenceSystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        // bioMarker.score = score;
                        const newBiomarker = {
                           ...bioMarker,
                           score,
                           createdAt: report.testedAt,
                        };

                        // bioMarker.createdAt = report.testedAt;
                        defenceSystemBiomarkers.push(newBiomarker);
                     }
                  });
                  cardiovascularSystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        const newBiomarker = {
                           ...bioMarker,
                           score,
                           createdAt: report.testedAt,
                        };
                        cardiovascularSystemBiomarkers.push(newBiomarker);
                     }
                  });
                  detoxSystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        const newBiomarker = {
                           ...bioMarker,
                           score,
                           createdAt: report.testedAt,
                        };
                        detoxSystemBiomarkers.push(newBiomarker);
                     }
                  });
                  energySystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        const newBiomarker = {
                           ...bioMarker,
                           score,
                           createdAt: report.testedAt,
                        };
                        energySystemBiomarkers.push(newBiomarker);
                     }
                  });
                  resilienceSystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        const newBiomarker = {
                           ...bioMarker,
                           score,
                           createdAt: report.testedAt,
                        };
                        resilienceSystemBiomarkers.push(newBiomarker);
                     }
                  });
                  structuralSystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        const newBiomarker = {
                           ...bioMarker,
                           score,
                           createdAt: report.testedAt,
                        };
                        structuralSystemBiomarkers.push(newBiomarker);
                     }
                  });
               }
            });
         });
      }

      dispatch(
         systemsActions.setBiomarkerList({
            biomarkerList: {
               defence: `${defenceSystemCount} / ${defenceSystemBiomarkerList.length}`,
               digest: `${digestiveSystemCount} / ${digestiveSystemBiomarkerList.length}`,
               cardio: `${cardiovascularSystemCount} / ${cardiovascularSystemBiomarkerList.length}`,
               detox: `${detoxSystemCount} / ${detoxSystemBiomarkerList.length}`,
               energy: `${energySystemCount} / ${energySystemBiomarkerList.length}`,
               resilience: `${resilienceSystemCount} / ${resilienceSystemBiomarkerList.length}`,
               structural: `${structuralSystemCount} / ${structuralSystemBiomarkerList.length}`,
            },
         })
      );

      finalReportScore =
         supporterFunction(digestiveSystemScore, digestiveSystemCount) +
         supporterFunction(
            cardiovascularSystemScore,
            cardiovascularSystemCount
         ) +
         supporterFunction(defenceSystemScore, defenceSystemCount) +
         supporterFunction(detoxSystemScore, detoxSystemCount) +
         supporterFunction(energySystemScore, energySystemCount) +
         supporterFunction(resilienceSystemScore, resilienceSystemCount) +
         supporterFunction(structuralSystemScore, structuralSystemCount);

      let finalReportSystemCount =
         checkSystemFunction(digestiveSystemCount) +
         checkSystemFunction(cardiovascularSystemCount) +
         checkSystemFunction(defenceSystemCount) +
         checkSystemFunction(detoxSystemCount) +
         checkSystemFunction(energySystemCount) +
         checkSystemFunction(resilienceSystemCount) +
         checkSystemFunction(structuralSystemCount);

      // Handler functions

      // Math.round(finalReportScore / finalReportSystemCount),
      finalReportHandler(
         Math.round(finalReportScore / 7),
         finalReportDate,
         finalReportLabName
      );

      digestiveSystemHandler(
         digestiveSystemBiomarkers,
         supporterFunction(digestiveSystemScore, digestiveSystemCount)
      );
      defenceSystemHandler(
         defenceSystemBiomarkers,
         supporterFunction(defenceSystemScore, defenceSystemCount)
      );

      cardioVascularSystemHandler(
         cardiovascularSystemBiomarkers,
         supporterFunction(cardiovascularSystemScore, cardiovascularSystemCount)
      );
      detoxSystemHandler(
         detoxSystemBiomarkers,
         supporterFunction(detoxSystemScore, detoxSystemCount)
      );
      energySystemHandler(
         energySystemBiomarkers,
         supporterFunction(energySystemScore, energySystemCount)
      );
      resilienceSystemHandler(
         resilienceSystemBiomarkers,
         supporterFunction(resilienceSystemScore, resilienceSystemCount)
      );

      structuralSystemHandler(
         structuralSystemBiomarkers,
         supporterFunction(structuralSystemScore, structuralSystemCount)
      );

      // Working on previous reports
      if (previousLatestReport) {
         if (previousLatestReport.biomarkers) {
            previousLatestReport.biomarkers.map((biomarker) => {
               const bioMarker = biomarker;
               let high;
               let low;
               let value;

               if (bioMarker.biomarker.filter) {
                  if (
                     patient.patient.gender === "male" ||
                     patient.patient.gender === "Male"
                  ) {
                     high = bioMarker.biomarker.male.rangeHigh;
                     low = bioMarker.biomarker.male.rangeLow;
                  } else {
                     high = bioMarker.biomarker.female.rangeHigh;
                     low = bioMarker.biomarker.female.rangeLow;
                  }
               } else {
                  high = bioMarker.biomarker.rangeHigh;
                  low = bioMarker.biomarker.rangeLow;
               }
               value = bioMarker.biomarkerValue;

               if (bioMarker.biomarker) {
                  digestiveSystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        //TODO: calculating the score dynamically
                        let score = calculateScore(value, (high + low) / 2);
                        digestiveSystemScore += score;
                        digestiveSystemCount++;
                     }
                  });
                  defenceSystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        defenceSystemScore += score;
                        defenceSystemCount++;
                     }
                  });
                  cardiovascularSystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        cardiovascularSystemScore += score;
                        cardiovascularSystemCount++;
                     }
                  });
                  detoxSystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        detoxSystemScore += score;
                        detoxSystemCount++;
                     }
                  });
                  energySystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        energySystemScore += score;
                        energySystemCount++;
                     }
                  });
                  resilienceSystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        resilienceSystemScore += score;
                        resilienceSystemCount++;
                     }
                  });
                  structuralSystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        structuralSystemScore += score;
                        structuralSystemCount++;
                     }
                  });
               }
            });
         }
      }

      previousLatestReportScore =
         supporterFunction(digestiveSystemScore, digestiveSystemCount) +
         supporterFunction(
            cardiovascularSystemScore,
            cardiovascularSystemCount
         ) +
         supporterFunction(defenceSystemScore, defenceSystemCount) +
         supporterFunction(detoxSystemScore, detoxSystemCount) +
         supporterFunction(energySystemScore, energySystemCount) +
         supporterFunction(resilienceSystemScore, resilienceSystemCount) +
         supporterFunction(structuralSystemScore, structuralSystemCount);

      let previousLatestReportSystemCount =
         checkSystemFunction(digestiveSystemCount) +
         checkSystemFunction(cardiovascularSystemCount) +
         checkSystemFunction(defenceSystemCount) +
         checkSystemFunction(detoxSystemCount) +
         checkSystemFunction(energySystemCount) +
         checkSystemFunction(resilienceSystemCount) +
         checkSystemFunction(structuralSystemCount);

      // Math.round(
      //    previousLatestReportScore / previousLatestReportSystemCount
      // ),

      previousLatestReportHandler(
         Math.round(previousLatestReportScore / 7),
         previousLatestReportDate,
         previousLatestReportLabName
      );

      if (previousOldReport) {
         if (previousOldReport.biomarkers) {
            previousOldReport.biomarkers.map((biomarker) => {
               const bioMarker = biomarker;

               let high;
               let low;
               let value;

               if (bioMarker.biomarker.filter) {
                  if (
                     patient.patient.gender === "male" ||
                     patient.patient.gender === "Male"
                  ) {
                     high = bioMarker.biomarker.male.rangeHigh;
                     low = bioMarker.biomarker.male.rangeLow;
                  } else {
                     high = bioMarker.biomarker.female.rangeHigh;
                     low = bioMarker.biomarker.female.rangeLow;
                  }
               } else {
                  high = bioMarker.biomarker.rangeHigh;
                  low = bioMarker.biomarker.rangeLow;
               }

               value = bioMarker.biomarkerValue;
               if (bioMarker.biomarker) {
                  digestiveSystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        digestiveSystemScore += score;
                        digestiveSystemCount++;
                     }
                  });
                  defenceSystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        defenceSystemScore += score;
                        defenceSystemCount++;
                     }
                  });
                  cardiovascularSystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        cardiovascularSystemScore += score;
                        cardiovascularSystemCount++;
                     }
                  });
                  detoxSystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        detoxSystemScore += score;
                        detoxSystemCount++;
                     }
                  });
                  energySystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        energySystemScore += score;
                        energySystemCount++;
                     }
                  });
                  resilienceSystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        resilienceSystemScore += score;
                        resilienceSystemCount++;
                     }
                  });
                  structuralSystemBiomarkerList.map((biomarker) => {
                     if (bioMarker.biomarker.name === biomarker) {
                        let score = calculateScore(value, (high + low) / 2);
                        structuralSystemScore += score;
                        structuralSystemCount++;
                     }
                  });
               }
            });
         }
      }

      previousOldReportScore =
         supporterFunction(digestiveSystemScore, digestiveSystemCount) +
         supporterFunction(
            cardiovascularSystemScore,
            cardiovascularSystemCount
         ) +
         supporterFunction(defenceSystemScore, defenceSystemCount) +
         supporterFunction(detoxSystemScore, detoxSystemCount) +
         supporterFunction(energySystemScore, energySystemCount) +
         supporterFunction(resilienceSystemScore, resilienceSystemCount) +
         supporterFunction(structuralSystemScore, structuralSystemCount);

      let previousOldReportSystemCount =
         checkSystemFunction(digestiveSystemCount) +
         checkSystemFunction(cardiovascularSystemCount) +
         checkSystemFunction(defenceSystemCount) +
         checkSystemFunction(detoxSystemCount) +
         checkSystemFunction(energySystemCount) +
         checkSystemFunction(resilienceSystemCount) +
         checkSystemFunction(structuralSystemCount);

      // Math.round(previousOldReportScore / previousOldReportSystemCount),

      previousOldReportHandler(
         Math.round(previousOldReportScore / 7),
         previousOldReportDate,
         previousOldReportLabName
      );

      dispatch(systemsActions.setLoadingState({ status: false }));
      if (previousOldReport.biomarkers) {
         showOldReport = true;
      } else {
         showOldReport = false;
      }

      if (previousLatestReport.biomarkers) {
         showRecentReport = true;
      } else {
         showRecentReport = false;
      }

      dispatch(
         systemsActions.setReportPreviousLatest({
            reportPreviousLatest: showRecentReport,
         })
      );

      dispatch(
         systemsActions.setReportPreviousOld({
            reportPreviousOld: showOldReport,
         })
      );
   }, [patient]);

   // Dispatch functions

   const digestiveSystemHandler = (biomarkerList, score) => {
      dispatch(systemsActions.digestiveSystem({ biomarkerList, score }));
   };

   const defenceSystemHandler = (biomarkerList, score) => {
      dispatch(systemsActions.defenceSystem({ biomarkerList, score }));
   };

   const cardioVascularSystemHandler = (biomarkerList, score) => {
      dispatch(systemsActions.cardiovascularSystem({ biomarkerList, score }));
   };

   const detoxSystemHandler = (biomarkerList, score) => {
      dispatch(systemsActions.detoxSystem({ biomarkerList, score }));
   };

   const energySystemHandler = (biomarkerList, score) => {
      dispatch(systemsActions.energySystem({ biomarkerList, score }));
   };

   const resilienceSystemHandler = (biomarkerList, score) => {
      dispatch(systemsActions.resilienceSystem({ biomarkerList, score }));
   };

   const structuralSystemHandler = (biomarkerList, score) => {
      dispatch(systemsActions.structuralSystem({ biomarkerList, score }));
   };

   const finalReportHandler = (score, date, labName) => {
      dispatch(
         systemsActions.setFinalReport({
            finalReport: { score, date, labName },
         })
      );
   };

   const previousOldReportHandler = (score, date, labName) => {
      dispatch(
         systemsActions.setPreviousOldReport({
            previousOldReport: { score, date, labName },
         })
      );
   };

   const previousLatestReportHandler = (score, date, labName) => {
      dispatch(
         systemsActions.setPreviousReport({
            previousLatestReport: { score, date, labName },
         })
      );
   };

   let firstName = "";
   let lastName = "";

   if (patient.patient) {
      firstName = patient.patient.firstName;
      lastName = patient.patient.lastName;
   }

   const LogoutHandler = () => {
      dispatch(systemsActions.setLoggedOutStatus());
      navigate("/");
   };

   return (
      <div className={classes.home}>
         <div ref={componentRef}>
            <header className={classes.header}>
               <div className={classes.headerContainer}>
                  <nav className={classes.nav}>
                     <div className={classes.brandContainer}>
                        <img
                           src={BrandLogo}
                           alt="Brand logo"
                           className={classes.brand}
                        />
                        <div className={classes.brandSupport}>
                           <b>{`${firstName} ${lastName}`}</b> / Your Body
                           System Report
                        </div>
                     </div>
                     <div className={classes.navigationWrapper}>
                        <div className={classes.homeIconWrapper}>
                           <img
                              src={HomeSvg}
                              alt="Home"
                              className={classes.homeIcon}
                           />
                        </div>
                        <DropDown />
                        <div
                           className={classes.logoutIconWrapper}
                           onClick={LogoutHandler}>
                           <img
                              src={LogoutSvg}
                              alt="Home"
                              className={classes.logoutIcon}
                           />
                        </div>
                     </div>
                  </nav>
               </div>
            </header>
            <div className={classes.horizontalLine}></div>
            <div className={classes.row}>
               <OverallHealthScore
                  showPreviousOld={showOldReport}
                  showPreviousLatest={showRecentReport}
               />

               <BodySystemBreakDown />
            </div>
         </div>
         <div className={classes.horizontalLine}></div>
         <Footer />

         <div className={classes.printContent}>
            <Link
               className={classes.buttonPrint}
               to="/print-page">
               <img
                  src={PrintSvg}
                  alt="printer logo"
               />
            </Link>
         </div>
      </div>
   );
};

export default Home;
