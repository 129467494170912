import classes from "./OverallHealthScore.module.css";
import GaugeChart from "react-gauge-chart";
import { MdNotificationsNone } from "react-icons/md";
import { useSelector } from "react-redux";

const OverallHealthScore = () => {
   const state = useSelector((state) => {
      return state;
   });

   const showPreviousLatest = state.reportPreviousLatest;
   const showPreviousOld = state.reportPreviousOld;

   const showRadialShortVersion = showPreviousLatest;

   let red = "#D95454";
   let yellow = "#FAC071";
   let green = "#6B8F59";

   let labName = state.finalReport.labName;

   if (!labName) {
      labName = "";
   }
   let score = state.finalReport.score;

   let date = state.finalReport.date;

   let previousLatestScore = state.previousLatestReport.score;
   let previousLatestDate = state.previousLatestReport.date;
   let previousLatestLabName = state.previousLatestReport.labName;

   let previousOldReportScore = "";
   let previousOldReportDate = "";
   let previousOldReportLabName = "";

   if (state.previousOldReport.score) {
      previousOldReportScore = state.previousOldReport.score;
      previousOldReportDate = state.previousOldReport.date;
      previousOldReportLabName = state.previousOldReport.labName;
   }

   const color = () => {
      if (score > 82.5) {
         return green;
      } else if (score > 65) {
         return yellow;
      } else return red;
   };

   const labelStyle = {
      color: color(score),
   };

   console.log(labName);

   return (
      <div className={classes.overallHealthScoreWrap}>
         {/* <div className={classes.bodySystemWrapper}> */}
         {/* <div className={classes.heading}>Overall Health Score</div> */}
         <div className={classes.cardWrapper}>
            <div className={classes.previousReportContainer}>
               {showPreviousOld && (
                  <div className={classes.card}>
                     <p className={classes.smallCardHeading}>
                        {new Date(previousOldReportDate).toLocaleDateString(
                           "en-us",
                           {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                           }
                        )}
                     </p>
                     <p className={classes.cardHealthRating}>
                        {previousOldReportScore}
                     </p>
                     <hr className={classes.horizontalLine} />
                     <p
                        className={
                           classes.cardLabName
                        }>{`LAB: ${previousOldReportLabName.toUpperCase()}`}</p>
                  </div>
               )}
               {showPreviousLatest && (
                  <div className={classes.card}>
                     <p className={classes.smallCardHeadingDark}>
                        {new Date(previousLatestDate).toLocaleDateString(
                           "en-us",
                           {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                           }
                        )}
                     </p>
                     <p className={classes.cardHealthRating}>
                        {previousLatestScore}
                     </p>
                     <hr className={classes.horizontalLine} />
                     <p
                        className={
                           classes.cardLabName
                        }>{`LAB: ${previousLatestLabName.toUpperCase()}`}</p>
                  </div>
               )}
            </div>
            {showRadialShortVersion && (
               <div className={classes.radialContainerWrapper}>
                  <div className={classes.cardGauge}>
                     <div className={classes.gaugeWrapper}>
                        <p className={classes.mainCardHeading}>
                           {new Date(date).toLocaleDateString("en-us", {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                           })}
                        </p>
                        <div className={classes.gaugeChartWrapper}>
                           <GaugeChart
                              id="gauge-chart3"
                              nrOfLevels={3}
                              colors={[red, yellow, green]}
                              arcWidth={0.3}
                              hideText={true}
                              percent={score / 100}
                              className={classes.gaugeChart}
                              textColor={color(score)}
                              needleBaseColor="#949292"
                              arcsLength={[0.65, 0.17, 0.18]}
                              arcPadding={0}
                              cornerRadius={0}
                           />
                           <span style={labelStyle}>{score} </span>
                        </div>
                        <hr className={classes.horizontalLine} />
                        <p
                           className={
                              classes.cardLabName
                           }>{`LAB: ${labName.toUpperCase()}`}</p>
                     </div>
                  </div>
               </div>
            )}

            {!showRadialShortVersion && (
               <div className={classes.cardGaugeFull}>
                  <div className={classes.topScoreWrapper}>
                     <div className={classes.topChart}>
                        <div className={classes.gaugeChartWrapper}>
                           <GaugeChart
                              id="gauge-chart3"
                              nrOfLevels={3}
                              colors={[red, yellow, green]}
                              arcWidth={0.3}
                              hideText="true"
                              percent={score / 100}
                              className={classes.gaugeChart}
                              textColor={color(score)}
                              needleBaseColor="#949292"
                              arcsLength={[0.65, 0.17, 0.18]}
                              arcPadding={0}
                              cornerRadius={0}
                           />
                        </div>
                        <div className={classes.horizontalLineBottomTop} />
                     </div>
                     <div className={classes.topChartLeft}>
                        <p className={classes.mainCardHeadingTop}>
                           {new Date(date).toLocaleDateString("en-us", {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                           })}
                        </p>
                        <p
                           className={classes.ScoreTopChart}
                           style={{ color: `${color(score)}` }}>
                           {score}
                        </p>

                        <p
                           className={
                              classes.cardLabName
                           }>{`LAB: ${labName.toUpperCase()}`}</p>
                     </div>
                  </div>
               </div>
            )}
         </div>
         {/* </div> */}
         {/* <div className={classes.centerContainer}> */}
         <div className={classes.cardWrapperBottom}>
            <div className={classes.cardBottom}>
               <div className={classes.healthSummary}>
                  <div>
                     <p className={classes.cardBottomHeading}>Health Summary</p>
                     <hr className={classes.horizontalLine} />
                  </div>

                  <div className={classes.paddingWrapper}>
                     <div
                        className={`${classes.healthSummaryIconContainer} ${classes.red}`}>
                        <MdNotificationsNone size={40} />
                        <p>{state.homePageMarkerCount.danger}</p>
                        {/* <p>3</p> */}
                     </div>
                     <p className={classes.healthSummaryBiomarkers}>
                        Biomarkers
                     </p>
                     <p className={classes.healthSummarySideNote}>
                        Require Immediate Attention
                     </p>
                     <div
                        className={`${classes.healthSummaryIconContainer} ${classes.yellow}`}>
                        <MdNotificationsNone size={40} />
                        <p>{state.homePageMarkerCount.warning}</p>
                        {/* <p>17</p> */}
                     </div>
                     <p className={classes.healthSummaryBiomarkers}>
                        Biomarkers
                     </p>
                     <p
                        className={`${classes.healthSummarySideNote} ${classes.horizontalLineBottom}`}>
                        Require Improvement
                     </p>
                  </div>
                  <div>
                     <hr className={classes.horizontalLine} />
                     <div className={classes.spacer}></div>
                  </div>
               </div>
            </div>
            <div className={classes.cardBottom}>
               <div className={classes.cardWrapContainer}>
                  <div>
                     <p className={classes.cardBottomHeading}>
                        Risk of III Health*
                     </p>
                     <hr className={classes.horizontalLine} />
                  </div>
                  <div className={classes.riskWrapper}>
                     <div className={classes.riskContainerWrapper}>
                        <div className={classes.riskCardContainer}>
                           <div className={classes.riskContainer}>
                              <div className={classes.circleGreen}></div>
                              <div className={classes.riskRangeContainer}>
                                 <p className={classes.riskIndicator}>
                                    Low Risk
                                 </p>
                                 <p className={classes.riskRange}>82.5 - 100</p>
                              </div>
                           </div>
                        </div>
                        <div className={classes.riskCardContainer}>
                           <div className={classes.riskContainer}>
                              <div className={classes.circleYellow}></div>
                              <div className={classes.riskRangeContainer}>
                                 <p className={classes.riskIndicator}>
                                    Medium Risk
                                 </p>
                                 <p className={classes.riskRange}>65 - 82.5</p>
                              </div>
                           </div>
                        </div>
                        <div className={classes.riskCardContainer}>
                           <div className={classes.riskContainer}>
                              <div className={classes.circleRed}></div>
                              <div className={classes.riskRangeContainer}>
                                 <p className={classes.riskIndicator}>
                                    High Risk
                                 </p>
                                 <p className={classes.riskRange}>0 - 65</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div>
                     <hr className={classes.horizontalLine} />
                     <div className={classes.spacer}></div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default OverallHealthScore;
