import classes from "./SystemTemplate.module.css";
import BrandLogo from "./../../assets/images/Brand logo.png";
import DoughnutChart from "../../components/DoughnutChart/DoughnutChart";
import BarChart from "../../components/BarChart/BarChart";
import grayTree from "./../../assets/images/Gray tree.png";
import Footer from "./../../components/Footer/Footer";
import HomeSvg from "../../assets/icons/home.svg";
import LogoutSvg from "../../assets/icons/log-out.svg";
import PrintSvg from "../../assets/icons/printer.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { systemsActions } from "../../store";
import { pageStyle } from "../common";

// import Login from "../Login/Login";
// import ReactToPrint from "react-to-print";
import { useReactToPrint } from "react-to-print";

const SystemTemplate = (props) => {
   const dispatch = useDispatch();
   let navigate = useNavigate();
   let componentRef = useRef(null);

   const loggedIn = useSelector((state) => {
      return state.isLoggedIn;
   });
   const patient = useSelector((state) => {
      return state.patient;
   });

   let state = useSelector((state) => {
      return state;
   });

   let systems;
   let score;
   let biomarkers;
   let system;

   if (props.path) {
      switch (props.path) {
         case "/defence-system":
            systems = state.systems.defence;
            score = state.defenceSystem.score;
            biomarkers = state.defenceSystem.biomarkers;
            system = "Defence";
            break;
         case "/digestive-system":
            systems = state.systems.digestive;
            score = state.digestiveSystem.score;
            biomarkers = state.digestiveSystem.biomarkers;
            system = "Digestive";
            break;
         case "/detox-system":
            systems = state.systems.detox;
            score = state.detoxSystem.score;
            biomarkers = state.detoxSystem.biomarkers;
            system = "Detox";
            break;
         case "/structural-system":
            systems = state.systems.structural;
            score = state.structuralSystem.score;
            biomarkers = state.structuralSystem.biomarkers;
            system = "Structural";
            break;
         case "/cardiovascular-system":
            systems = state.systems.cardiovascular;
            score = state.cardiovascularSystem.score;
            biomarkers = state.cardiovascularSystem.biomarkers;
            system = "Cardiovascular";
            break;
         case "/energy-system":
            systems = state.systems.energy;
            score = state.energySystem.score;
            biomarkers = state.energySystem.biomarkers;
            system = "Energy";
            break;
         case "/resilience-system":
            systems = state.systems.resilience;
            score = state.resilienceSystem.score;
            biomarkers = state.resilienceSystem.biomarkers;
            system = "Resilience";
            break;

         default:
            break;
      }
   } else {
      switch (window.location.pathname) {
         case "/defence-system":
            systems = state.systems.defence;
            score = state.defenceSystem.score;
            biomarkers = state.defenceSystem.biomarkers;
            system = "Defence";
            break;
         case "/digestive-system":
            systems = state.systems.digestive;
            score = state.digestiveSystem.score;
            biomarkers = state.digestiveSystem.biomarkers;
            system = "Digestive";
            break;
         case "/detox-system":
            systems = state.systems.detox;
            score = state.detoxSystem.score;
            biomarkers = state.detoxSystem.biomarkers;
            system = "Detox";
            break;
         case "/structural-system":
            systems = state.systems.structural;
            score = state.structuralSystem.score;
            biomarkers = state.structuralSystem.biomarkers;
            system = "Structural";
            break;
         case "/cardiovascular-system":
            systems = state.systems.cardiovascular;
            score = state.cardiovascularSystem.score;
            biomarkers = state.cardiovascularSystem.biomarkers;
            system = "Cardiovascular";
            break;
         case "/energy-system":
            systems = state.systems.energy;
            score = state.energySystem.score;
            biomarkers = state.energySystem.biomarkers;
            system = "Energy";
            break;
         case "/resilience-system":
            systems = state.systems.resilience;
            score = state.resilienceSystem.score;
            biomarkers = state.resilienceSystem.biomarkers;
            system = "Resilience";
            break;

         default:
            break;
      }
   }

   const [biomarkerListTiles, setBiomarkerListTiles] = useState([]);
   const [showHome, setShowHome] = useState(true);

   const green = "rgba(107, 143, 89, 1)";
   const yellow = "rgba(240, 180, 41, 1)";
   const red = "rgba(217, 84, 84, 1)";

   let biomarkerList = [];

   const biomarkerTiles = (biomarkerList) => {
      // console.log(biomarkerList);
      let biomarkerTilesList = [];
      biomarkerList.map((biomarker) => {
         const reference = biomarker.map((el) => {
            const date = new Date(el.date);
            // console.log(el);
            return {
               date: `${date.getFullYear()}-${
                  date.getMonth() + 1
               }-${date.getDate()}`,
               score: el.score,
               value: el.value,
               high: el.high,
               low: el.low,
            };
         });
         const chartTile = (
            <BarChart
               key={Math.random()}
               title={biomarker[0].name}
               subTitle={biomarker[0].unit}
               labelList={reference
                  .map((el) => {
                     return el.date;
                  })
                  .reverse()}
               colors={reference
                  .map((el) => {
                     if (el.value > el.high || el.value < el.low) {
                        return red;
                     }
                     if (el.score > 82.5) {
                        return green;
                     } else if (el.score > 65) {
                        return yellow;
                     } else {
                        if (el.value > el.low || el.value < el.high)
                           return yellow;
                        return red;
                     }
                  })
                  .reverse()}
               dataValues={reference
                  .map((el) => {
                     if (typeof el.value === "number") {
                        return el.value;
                     } else {
                        return "";
                     }
                  })
                  .reverse()}
            />
         );
         biomarkerTilesList.push(chartTile);
      });
      setBiomarkerListTiles(biomarkerTilesList);
   };

   useEffect(() => {
      if (!loggedIn) {
         navigate("/");
      }

      if (systems) {
         systems.map((name) => {
            // console.log(name);
            let IndividualList = [];
            biomarkers.map((biomarker) => {
               if (biomarker.biomarker.name === name) {
                  let biomarkerTile = {};
                  biomarkerTile.name = name;
                  biomarkerTile.date = biomarker.createdAt;
                  biomarkerTile.value = biomarker.biomarkerValue;
                  if (biomarker.biomarker.filter) {
                     if (
                        patient.patient.gender === "male" ||
                        patient.patient.gender === "Male"
                     ) {
                        biomarkerTile.high = biomarker.biomarker.male.rangeHigh;
                        biomarkerTile.low = biomarker.biomarker.male.rangeLow;
                     } else {
                        biomarkerTile.high =
                           biomarker.biomarker.female.rangeHigh;
                        biomarkerTile.low = biomarker.biomarker.female.rangeLow;
                     }
                  } else {
                     biomarkerTile.high = biomarker.biomarker.rangeHigh;
                     biomarkerTile.low = biomarker.biomarker.rangeLow;
                  }

                  biomarkerTile.unit = biomarker.biomarker.unit;
                  biomarkerTile.score = biomarker.score;

                  IndividualList.push(biomarkerTile);
               }
            });

            if (IndividualList.length > 0) {
               biomarkerList.push(IndividualList);
            }
         });
      }

      // console.log(biomarkerList);

      /// Check if the reports are completed
      biomarkerList.map((IndividualList, i) => {
         let thirdReport = false;
         let finalReport = false;
         let secondReport = false;
         let fourthReport = false;
         let fifthReport = false;

         IndividualList.map((biomarker) => {
            if (!finalReport) {
               if (
                  new Date(state.reportLastDate).getTime() ===
                  new Date(biomarker.date).getTime()
               ) {
                  finalReport = true;
               }
            }

            if (!secondReport) {
               if (
                  new Date(state.secondReportDate).getTime() ===
                  new Date(biomarker.date).getTime()
               ) {
                  secondReport = true;
               }
            }

            if (!thirdReport) {
               if (
                  new Date(state.thirdReportDate).getTime() ===
                  new Date(biomarker.date).getTime()
               ) {
                  thirdReport = true;
               }
            }

            if (!fourthReport) {
               if (
                  new Date(state.fourthReportDate).getTime() ===
                  new Date(biomarker.date).getTime()
               ) {
                  fourthReport = true;
               }
            }

            if (!fifthReport) {
               if (
                  new Date(state.fifthReportDate).getTime() ===
                  new Date(biomarker.date).getTime()
               ) {
                  fifthReport = true;
               }
            }
         });

         if (!finalReport) {
            let biomarker = {};
            biomarker = { ...IndividualList[0] };
            biomarker.value = "0";
            biomarker.score = 0;
            biomarker.date = state.reportLastDate;

            if (state.reportLastDate) {
               IndividualList.push(biomarker);
            }
         }

         if (!secondReport) {
            let biomarker = {};
            biomarker = { ...IndividualList[0] };
            biomarker.value = "0";
            biomarker.score = 0;
            biomarker.date = state.secondReportDate;

            if (state.secondReportDate) {
               biomarkerList[i].push(biomarker);
            }
         }

         if (!thirdReport) {
            let biomarker = {};
            biomarker = { ...IndividualList[0] };
            biomarker.value = "0";
            biomarker.score = 0;
            biomarker.date = state.thirdReportDate;

            if (state.thirdReportDate) {
               IndividualList.push(biomarker);
            }
         }

         if (!fourthReport) {
            let biomarker = {};
            biomarker = { ...IndividualList[0] };
            biomarker.value = "0";
            biomarker.score = 0;
            biomarker.date = state.fourthReportDate;

            if (state.fourthReportDate) {
               biomarkerList[i].push(biomarker);
            }
         }

         if (!fifthReport) {
            let biomarker = {};
            biomarker = { ...IndividualList[0] };
            biomarker.value = "0";
            biomarker.score = 0;
            biomarker.date = state.fifthReportDate;

            if (state.fifthReportDate) {
               biomarkerList[i].push(biomarker);
            }
         }

         biomarkerList[i].sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
         });
      });

      biomarkerTiles(biomarkerList);
   }, [location.pathname]);

   const navigateHandler = () => {
      navigate("/dashboard");
   };

   const LogoutHandler = () => {
      dispatch(systemsActions.setLoggedOutStatus());

      navigate("/");
   };

   const hideIconHandler = () => {
      setShowHome(false);
      return true;
   };

   const showIconHandler = () => {
      setShowHome(true);
   };

   const handlePrint = useReactToPrint({
      pageStyle,
      onAfterPrint: () => {
         showIconHandler();
      },
      content: () => {
         return componentRef.current;
      },
   });

   const calculateScore = (score) => {
      if (score > 82.5) {
         return "rgba(107, 143, 89, 1)";
      } else if (score > 65) {
         return "rgba(240, 180, 41, 1) ";
      } else {
         return "rgba(217, 84, 84, 1)";
      }
   };

   // rgba(107, 143, 89, 1) - green
   // rgba(240, 180, 41, 1) - yellow
   // rgba(217, 84, 84, 1) - red

   let firstName = "";
   let lastName = "";

   if (patient.patient) {
      firstName = patient.patient.firstName;
      lastName = patient.patient.lastName;
   }
   return (
      <div className={classes.headerContainer}>
         <div ref={componentRef}>
            <header className={classes.header}>
               <div className={classes.headerContainer}>
                  <nav className={classes.nav}>
                     <div className={classes.brandContainer}>
                        <img
                           src={BrandLogo}
                           alt="Brand logo"
                           className={classes.brand}
                        />
                        <div className={classes.brandSupport}>
                           <b>{`${firstName} ${lastName}`}</b> / Your Health
                           Report
                        </div>
                     </div>
                     <div className={classes.systemWrapper}>
                        <p>{`${system}`} System Analysis</p>
                        <div>
                           <DoughnutChart
                              value={score.toString()}
                              color={`${calculateScore(score)}`}
                           />
                        </div>
                     </div>
                     {showHome && (
                        <div className={classes.navigationWrapper}>
                           <div
                              className={classes.homeIconWrapper}
                              onClick={navigateHandler}>
                              <img
                                 src={HomeSvg}
                                 alt="Home"
                                 className={classes.homeIcon}
                              />
                           </div>

                           <div
                              className={classes.logoutIconWrapper}
                              onClick={LogoutHandler}>
                              <img
                                 src={LogoutSvg}
                                 alt="Home"
                                 className={classes.logoutIcon}
                              />
                           </div>
                        </div>
                     )}
                  </nav>
               </div>
            </header>
            <div className={classes.horizontalLine}></div>
            <div className={classes.chartWrapper}>
               <div className={classes.barChartWrapper}>
                  {biomarkerListTiles}
                  <img
                     src={grayTree}
                     alt="Gray tree"
                     className={classes.grayTree}
                  />
               </div>
            </div>
         </div>
         <div className={classes.horizontalLine}></div>
         <Footer />

         <div className={classes.printContent}>
            <button
               className={classes.buttonPrint}
               onClick={() => window.print()}>
               <img
                  src={PrintSvg}
                  alt="printer logo"
               />
            </button>
         </div>
      </div>
   );
};

export default SystemTemplate;
