import { Link } from "react-router-dom";
import DoughnutChart from "../DoughnutChart/DoughnutChart";
import classes from "./SystemCard.module.css";

const SystemCard = (props) => {
   const { system, markers, description, color, value, slug } = props;
   return (
      <div className={classes.systemCard}>
         <div>
            <DoughnutChart
               color={color}
               value={value}
            />
         </div>
         <Link
            to={slug}
            className={classes.link}>
            <div className={classes.detailsArea}>
               <h3>{system}</h3>
               <p>{markers}</p>
               <p>{description}</p>
            </div>
         </Link>
      </div>
   );
};

export default SystemCard;
