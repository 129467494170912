// @ts-nocheck
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import PatientDetailsEntryPage from "./pages/PatientDetailsEntryPage/PatientDetailsEntryPage";
import Login from "./pages/Login/Login";
import { Overlay } from "./components/Model/Model";
import Loading from "./components/Loading/Loading";
import { useSelector } from "react-redux";
import PatientDeletePage from "./pages/PatientDeletePage/PatientDeletePage";
import CSVUpload from "./pages/CSVUpload/CSVUpload";
import React from "react";
import SystemTemplate from "./pages/SystemTemplate/SystemTemplate";
import PrintPage from "./pages/PrintPage/PrintPage";

const App = () => {
   const status = useSelector((status) => {
      return status;
   });

   return (
      <>
         <Routes>
            <Route
               path="/"
               element={<Login />}
            />

            <Route
               path="/dashboard"
               element={<Home />}
            />
            <Route
               path="/defence-system"
               element={<SystemTemplate />}
            />
            <Route
               path="/digestive-system"
               element={<SystemTemplate />}
            />
            <Route
               path="/detox-system"
               element={<SystemTemplate />}
            />
            <Route
               path="/structural-system"
               element={<SystemTemplate />}
            />
            <Route
               path="/cardiovascular-system"
               element={<SystemTemplate />}
            />
            <Route
               path="/energy-system"
               element={<SystemTemplate />}
            />
            <Route
               path="/resilience-system"
               element={<SystemTemplate />}
            />
            <Route
               path="/patient-details"
               element={<PatientDetailsEntryPage />}
            />
            <Route
               path="/patient-delete"
               element={<PatientDeletePage />}
            />
            <Route
               path="/csv-upload"
               element={<CSVUpload />}
            />

            <Route
               path="/print-page"
               element={<PrintPage />}
            />
         </Routes>
         <Overlay isOpen={status.isLoading} />
         <Loading isOpen={status.isLoading} />
      </>
   );
};

export default App;
