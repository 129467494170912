export const sortLimit = (array, limit) => {
   let arraynew = array.slice();
   let sortedArray = arraynew.sort(function (a, b) {
      return new Date(b.testedAt) - new Date(a.testedAt);
   });

   // let sortedArray = array.sort(function (a, b) {
   //    return new Date(a.testedAt) - new Date(b.testedAt);
   // });

   return sortedArray.slice(0, limit);
};
