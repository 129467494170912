import { createPortal } from "react-dom";
import classes from "./Model.module.css";
import successIcon from "./../../assets/icons/check-icon.svg";
import errorIcon from "./../../assets/icons/error-icon.svg";
import alertIcon from "./../../assets/icons/alert.svg";

const Model = ({ isOpen, modelHandler, responseStatus, clearInputFields }) => {
   const newUserClickHandler = () => {
      clearInputFields("all");
      modelHandler(false);
   };

   const closeModelHandler = () => {
      modelHandler(false);
   };

   const onAddAnotherReportHandler = () => {
      clearInputFields();
      modelHandler(false);
   };

   const onSendReport = () => {
      clearInputFields();
      modelHandler(false, true);
   };

   const onCancelReport = () => {
      modelHandler(false, false);
      clearInputFields();
   };

   if (!isOpen) return null;

   if (responseStatus === "info") {
      return createPortal(
         <div className={classes.model}>
            <div className={classes.iconWrapper}>
               {/* <img
                  src={successIcon}
                  className={classes.successIcon}
               /> */}
            </div>
            <h1 className={classes.heading}>UPLOAD INFO</h1>
            <div className={classes.messageTitleInfo}>
               1) Please make sure that the file you are uploading is a CSV
               file.
               <br />
               2) Date should be in the format of YYYY-MM-DD.
               <br />
               3) Please make sure that the reports are only oriented to a one
               patient.
               <br />
            </div>
            <div className="d-flex justify-content-center">
               <button
                  className={`btn btn-success ${classes.button}`}
                  onClick={onCancelReport}>
                  Okay
               </button>
            </div>
         </div>,
         document.getElementById("model")
      );
   }

   if (responseStatus === "api-success") {
      return createPortal(
         <div className={classes.model}>
            <div className={classes.iconWrapper}>
               <img
                  src={successIcon}
                  className={classes.successIcon}
               />
            </div>
            <div className={classes.messageTitleWarnig}>
               The process is successfully completed.
            </div>
            <div className="d-flex justify-content-center">
               <button
                  className={`btn btn-success ${classes.button}`}
                  onClick={onCancelReport}>
                  Okay
               </button>
            </div>
         </div>,
         document.getElementById("model")
      );
   }

   if (responseStatus === "confirmation") {
      return createPortal(
         <div className={classes.model}>
            <div className={classes.iconWrapper}>
               <img
                  src={alertIcon}
                  className={classes.successIcon}
               />
            </div>
            <div className={classes.messageTitleWarnig}>
               Please check the credentials before confirmation. This change
               will be permenent.
            </div>
            <div className="d-flex justify-content-between">
               <button
                  className={`btn btn-warning ${classes.button}`}
                  onClick={onCancelReport}>
                  Cancel
               </button>
               <button
                  className={`btn btn-success ${classes.button}`}
                  onClick={onSendReport}>
                  Submit
               </button>
            </div>
         </div>,
         document.getElementById("model")
      );
   }

   if (responseStatus === "api-error") {
      return createPortal(
         <div className={classes.model}>
            <div className={classes.iconWrapper}>
               <img
                  src={errorIcon}
                  className={classes.successIcon}
               />
            </div>

            <div className={classes.messageTitleWarnig}>
               Something went very wrong. Please contact the system admin.
            </div>
            <div className="d-flex justify-content-center">
               <button
                  className={`btn btn-warning ${classes.button}`}
                  onClick={closeModelHandler}>
                  Okay
               </button>
            </div>
         </div>,
         document.getElementById("model")
      );
   }

   if (responseStatus === "errorForm") {
      return createPortal(
         <div className={classes.model}>
            <div className={classes.iconWrapper}>
               <img
                  src={errorIcon}
                  className={classes.successIcon}
               />
            </div>

            <div className={classes.messageTitleError}>
               Please complete the required fields of the form
            </div>
            <div className="d-flex justify-content-end">
               <button
                  className={`btn btn-warning ${classes.button}`}
                  onClick={closeModelHandler}>
                  Try again
               </button>
            </div>
         </div>,
         document.getElementById("model")
      );
   }

   if (responseStatus === "error" || responseStatus === "fail") {
      return createPortal(
         <div className={classes.model}>
            <div className={classes.iconWrapper}>
               <img
                  src={errorIcon}
                  className={classes.successIcon}
               />
            </div>

            <div className={classes.messageTitleError}>
               Something went wrong. Please try again
            </div>
            <div className="d-flex justify-content-between">
               <button
                  className={`btn btn-success ${classes.button}`}
                  onClick={onAddAnotherReportHandler}>
                  Try again
               </button>
            </div>
         </div>,
         document.getElementById("model")
      );
   }

   return createPortal(
      <div className={classes.model}>
         <div className={classes.iconWrapper}>
            <img
               src={successIcon}
               className={classes.successIcon}
            />
         </div>
         <div className={classes.messageTitle}>
            Successfully created the report
         </div>
         <div className="d-flex justify-content-between">
            <button
               className={`btn btn-warning ${classes.button}`}
               onClick={newUserClickHandler}>
               New user
            </button>
            <button
               className={`btn btn-dark ${classes.button}`}
               onClick={onAddAnotherReportHandler}>
               Add another report
            </button>
         </div>
      </div>,
      document.getElementById("model")
   );
};

export default Model;

export const Overlay = ({ isOpen }) => {
   if (!isOpen) return null;
   return createPortal(
      <div className={classes.portal}></div>,
      document.getElementById("overlay")
   );
};
