import { createSlice, configureStore } from "@reduxjs/toolkit";

const initialState = {
   patient: {},
   user: {},
   reportPreviousLatest: false,
   reportPreviousOld: false,
   secondReportDate: null,
   thirdReportDate: null,
   fourthReportDate: null,
   fifthReportDate: null,
   reportLastDate: null,
   isUpdateReport: null,
   homePageMarkerCount: {},
   biomarkerList: {},
   finalReport: {
      date: "",
      labName: "",
      score: 0,
   },
   previousLatestReport: {
      date: "",
      labName: "",
      score: 0,
   },
   previousOldReport: {
      date: "",
      labName: "",
      score: 0,
   },
   isLoading: false,
   isLoggedIn: false,
   systems: [],
   defenceSystem: {
      score: 0,
      biomarkers: [],
   },
   digestiveSystem: {
      score: 0,
      biomarkers: [],
   },
   cardiovascularSystem: {
      score: 0,
      biomarkers: [],
   },
   detoxSystem: {
      score: 0,
      biomarkers: [],
   },
   energySystem: {
      score: 0,
      biomarkers: [],
   },
   resilienceSystem: {
      score: 0,
      biomarkers: [],
   },
   structuralSystem: {
      score: 0,
      biomarkers: [],
   },
};

const systemsSlice = createSlice({
   name: "systems",
   initialState,
   reducers: {
      defenceSystem(state, action) {
         const biomarkers = action.payload.biomarkerList;
         const score = Math.round(action.payload.score);
         return { ...state, defenceSystem: { biomarkers, score } };
      },
      digestiveSystem(state, action) {
         const biomarkers = action.payload.biomarkerList;
         const score = Math.round(action.payload.score);
         return {
            ...state,
            digestiveSystem: { score, biomarkers },
         };
      },
      cardiovascularSystem(state, action) {
         const biomarkers = action.payload.biomarkerList;
         const score = Math.round(action.payload.score);
         return {
            ...state,
            cardiovascularSystem: { score, biomarkers },
         };
      },
      detoxSystem(state, action) {
         const biomarkers = action.payload.biomarkerList;
         const score = Math.round(action.payload.score);
         return {
            ...state,
            detoxSystem: { score, biomarkers },
         };
      },
      energySystem(state, action) {
         const biomarkers = action.payload.biomarkerList;
         const score = Math.round(action.payload.score);
         return {
            ...state,
            energySystem: { score, biomarkers },
         };
      },
      resilienceSystem(state, action) {
         const biomarkers = action.payload.biomarkerList;
         const score = Math.round(action.payload.score);
         return {
            ...state,
            resilienceSystem: { score, biomarkers },
         };
      },
      structuralSystem(state, action) {
         const biomarkers = action.payload.biomarkerList;
         const score = Math.round(action.payload.score);
         return {
            ...state,
            structuralSystem: { score, biomarkers },
         };
      },
      setPatientState(state, action) {
         const patient = action.payload.patient;
         return { ...state, patient };
      },
      setSystems(state, action) {
         const systems = action.payload.systems;
         return { ...state, systems };
      },
      setFinalReport(state, action) {
         const finalReport = action.payload.finalReport;
         return { ...state, finalReport };
      },
      setPreviousReport(state, action) {
         const previousLatestReport = action.payload.previousLatestReport;
         return { ...state, previousLatestReport };
      },
      setPreviousOldReport(state, action) {
         const previousOldReport = action.payload.previousOldReport;
         return { ...state, previousOldReport };
      },
      setLoadingState(state, action) {
         const isLoading = action.payload.status;
         return { ...state, isLoading };
      },
      setLoggedInStatus(state) {
         return { ...state, isLoggedIn: true };
      },
      setLoggedOutStatus(state) {
         return {
            ...initialState,
         };
         // return {};
      },
      setData(state, action) {
         const user = action.payload.user;
         return { ...state, user };
      },
      setHomePageMarkerCount(state, action) {
         const homePageMarkerCount = action.payload.homePageMarkerCount;
         return { ...state, homePageMarkerCount };
      },
      setBiomarkerList(state, action) {
         const biomarkerList = action.payload.biomarkerList;
         return { ...state, biomarkerList };
      },
      setReportPreviousOld(state, action) {
         const reportPreviousOld = action.payload.reportPreviousOld;

         return { ...state, reportPreviousOld };
      },
      setReportPreviousLatest(state, action) {
         const reportPreviousLatest = action.payload.reportPreviousLatest;

         return { ...state, reportPreviousLatest };
      },

      setIsUpdateReport(state, action) {
         const isUpdateReport = action.payload.isUpdateReport;
         return { ...state, isUpdateReport };
      },

      setSecondReportDate(state, action) {
         const secondReportDate = action.payload.secondReportDate;
         return { ...state, secondReportDate };
      },

      setThirdReportDate(state, action) {
         const thirdReportDate = action.payload.thirdReportDate;
         return { ...state, thirdReportDate };
      },

      setReportLastDate(state, action) {
         const reportLastDate = action.payload.reportLastDate;
         return { ...state, reportLastDate };
      },
      setFourthReportDate(state, action) {
         const fourthReportDate = action.payload.fourthReportDate;
         return { ...state, fourthReportDate };
      },

      setFifthReportDate(state, action) {
         const fifthReportDate = action.payload.fifthReportDate;
         return { ...state, fifthReportDate };
      },
   },
});

const store = configureStore({
   reducer: systemsSlice.reducer,
   devTools: true,
});

export const systemsActions = systemsSlice.actions;
export default store;
