import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import classes from "./DoughnutChart.module.css";

const DoughnutChart = (props) => {
   const { value, color } = props;
   const data = {
      labels: ["2014"],
      datasets: [
         {
            label: "My First Dataset",
            data: [+value, 100 - +value],
            backgroundColor: [
               //    "rgba(240, 180, 41, 1)",
               color,
               "rgba(190, 193, 196, 1)",
            ],
            // borderWidth: 1,
         },
      ],
   };
   return (
      <div className={classes.doughnutChart}>
         <p>{value}</p>
         <Doughnut
            className={classes.doughnutChartClass}
            data={data}
            height={100}
            width={100}
            options={{
               responsive: true,
               maintainAspectRatio: true,
               scales: {
                  y: {
                     beginAtZero: true,
                     display: false,
                  },
               },
               plugins: {
                  tooltip: {
                     enabled: false,
                  },
                  legend: {
                     display: false,
                  },
                  datalabels: {
                     display: false,
                  },
               },
               cutout: 32,
            }}
         />
      </div>
   );
};

export default DoughnutChart;
