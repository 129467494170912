import FooterLogo from "./../../assets/images/Slogan.png";
import classes from "./Footer.module.css";
import rightArrow from "./../../assets/icons/right-arrow.svg";
import leftArrow from "./../../assets/icons/left-arrow.svg";
import { useLocation, useNavigate } from "react-router-dom";

const Footer = () => {
   const location = useLocation();
   const navigate = useNavigate();

   const navigationRoutes = [
      "/dashboard",
      "/defence-system",
      "/digestive-system",
      "/detox-system",
      "/structural-system",
      "/cardiovascular-system",
      "/energy-system",
      "/resilience-system",
   ];

   const currentRoute = `${location.pathname}`;

   let currentValue = navigationRoutes.findIndex((route) => {
      return route === currentRoute;
   });

   return (
      <div className={classes.footer}>
         <img
            src={FooterLogo}
            alt="Footer logo"
         />
         <div className={classes.navigationPanel}>
            <img
               src={leftArrow}
               alt="Left Arrow"
               className={classes.arrowIcons}
               onClick={() => {
                  if (currentValue === 0) navigate(navigationRoutes[7]);
                  navigate(navigationRoutes[currentValue - 1]);
               }}
            />
            <img
               src={rightArrow}
               alt="Right Arrow"
               onClick={() => {
                  if (currentValue === 7) navigate(navigationRoutes[0]);
                  navigate(navigationRoutes[currentValue + 1]);
               }}
               className={classes.arrowIcons}
            />
         </div>
      </div>
   );
};

export default Footer;
