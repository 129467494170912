import { useLocation, useNavigate } from "react-router-dom";
import classes from "./DropDown.module.css";

export const DropDown = () => {
   let navigate = useNavigate();
   let value = "Home";
   const handleChange = (event) => {
      navigate(`/${event.target.value}`);
   };
   const options = [
      { label: "Select System ... ", value: "Home" },
      { label: "Defence System", value: "defence-system" },
      { label: "Digestive System", value: "digestive-system" },
      { label: "Detox System", value: "detox-system" },
      { label: "Structural System", value: "structural-system" },
      { label: "Cardiovascular System", value: "cardiovascular-system" },
      { label: "Energy System", value: "energy-system" },
      { label: "Resilience System", value: "resilience-system" },
   ].map((item) => {
      let location = useLocation();
      const path = location.pathname.substring(1);

      if (path === item.value) {
         return (
            <option
               key={item.value}
               selected={true}
               value={item.value}>
               {item.label}
            </option>
         );
      } else {
         return (
            <option
               key={item.value}
               value={item.value}>
               {item.label}
            </option>
         );
      }
   });

   return (
      <div>
         <label>
            <div className={classes.dropdownWrapper}>
               <select
                  className={classes.dropdownMenu}
                  placeholder="Select System"
                  value={value}
                  onChange={handleChange}>
                  {options}
               </select>
            </div>
         </label>
      </div>
   );
};
